import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Paper, Select, FormControl, MenuItem } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  search: {
    flexGrow: 1,
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon
  },
  searchInput: {
    flexGrow: 1
  },
}));

const FilterBar = props => {

  const classes = useStyles();
  const { onFilter, filterList, filterValue, placeHolder } = props;

  return (
    <div>
      <Paper
        className={classes.search}
        elevation={1}
      >
        <FilterListIcon className={classes.searchIcon} />
        <FormControl className={classes.searchInput}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterValue}
            onChange={onFilter}
            defaultValue={0}
            fullWidth
            disableUnderline
          >
            <MenuItem value={0}><em>{placeHolder}</em></MenuItem>
            {filterList.map((filter, i) => (
              <MenuItem key={i} value={filter.id}>{filter.nama}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
    </div>
  );
};

FilterBar.propTypes = {
  onFilter: PropTypes.func,
  filterList: PropTypes.array,
  filterValue: PropTypes.string,
  placeHolder: PropTypes.string,
};

export default FilterBar;
