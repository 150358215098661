import React from "react";
import PropTypes from 'prop-types';
import { Line } from "react-chartjs-2";

// eslint-disable-next-line no-use-before-define
function MultiLine (props) {
  // const { className} = props;


// const def = {
//   title: '',
//   values1: [],
//   values2: [],
//   labels: [],
// };
const options = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: 'Chart.js Line Chart - Multi Axis',
    },
  },
  scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'left',
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};


const datachart = {
  labels: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
  datasets: [
    {
      label: "First dataset",
      data: [33, 25, 35, 51, 54, 76],
      fill: true,
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)"
    },
    {
      label: "Second dataset",
      data: [33, 25, 35, 51, 54, 76],
      fill: false,
      borderColor: "#742774"
    }
  ]
};
let data = props.data != null ? props.data : datachart

return (
  <div className="MultiLine">
    <Line options={options} data={data} />
  </div>
);
}

MultiLine.propTypes = {
  className: PropTypes.string
};
export default MultiLine;
