import React, { useState, useEffect, useContext } from 'react';
import UrlServer from 'constants/urlserver';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Page, Header, SearchBar } from 'components';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Container, Grid, Divider, Avatar } from '@material-ui/core';
import swal from '@sweetalert/with-react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import './Pagination.css';
import { Favorite } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  page: { margin : 15},
  wrapper: {
    [theme.breakpoints.down('sm')]: {
      display:'flex',
      position: 'absolute',
      zIndex: 2,
      backgroundColor: '#F4F6F8',
      top: '10px',
      left: '10px',
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      display:'flex',
    position: 'absolute',
    zIndex: 2,
    backgroundColor: '#F4F6F8',
    top: '10px',
    left: '10px',
    width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      display:'flex',
    position: 'absolute',
    zIndex: 2,
    backgroundColor: '#F4F6F8',
    top: '10px',
    left: '10px',
    width: '100%'
    }
    
  },
  root: {
    maxWidth: 360,
    margin:5
  },
  media: {
    width: '100%',
    height: '170px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500]
  },
  text:{
    color:"#FF9100",
    marginBottom:"-10px",
  },
  media2: {
    marginTop:"10px",
    height: 300,
    width: '100%',
    flexShrink: 0,
    flexGrow: 0,
  },
}));

const Img = props => {
  const classes = useStyles();
  const [data, setData] = useState([])
  const [gbr, setGbr] = useState()
  const [dialog, setDialog] = useState()
  const [list, setList] = useState([])
  const [simpan, setSimpan] = useState()
  const [filter, setFilter] = useState([])
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState();
  const { onImage, formName, ...rest } = props;
  const perPage = 12;

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;

    setCurrentPage(selectedPage);
    setOffset(offset);
    fetchData();
  };


  const fetchData = () => {
      axios
      .get(UrlServer.address + 'api/v1/images', {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt'
        }
      })
      .then(res => {
        const list = res.data.result;
        var newlist = [];
        list.forEach(element => {
          if (element.match(/.(jpg|jpeg|png|gif|webp|svg|ico)$/i))
            newlist.push(element);
        });
        var data = newlist.slice(offset, offset + perPage);
        console.log(data)
        setData(newlist);
        setPageCount(Math.ceil(newlist.length / perPage));
        setFilter(data);
      });
  }

  const handleDelete = (e) => {
    swal({ title: "yakin hapus data ?", icon: "error", buttons: ["Batal", "Ya"], dangerMode: true })
            .then((willDelete) => {
              if (willDelete) {
                fetch(UrlServer.address + 'api/v1/images', {
                  method:'delete',
                  headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt',
                  },
                  body: JSON.stringify({nama : e})
                }).then(res => res.json())
               .then(res => {
                if(res.body == "-1"){
                      swal({ title: 'Image gagal dihapus!', icon: "error" })
                    }else{
                    swal({ title: 'Image berhasil dihapus.', icon: "success" })
                    .then((value) => {
                      setOpen(false)
                      fetchData()
                    });
                  }
                })
                
              }
            })
     }

     const handleDialog = e => {
      //  console.log(e)
      if (e != undefined) {
        setDialog(e);
      } else {
        setDialog('no-image.png');
      }
      setOpen(true);
    };

    let arr = [];
    
    const handleCheck = (idx) => {
      // setColor([...color, {id:idx}])
      if(arr.length !== 0){
         var index = arr.findIndex(el => el == idx) 
        //  console.log(index)
         if(index != -1){
         arr.splice(index, 1); 
          }else{
            arr.push(idx)
          }
      }else{
        arr.push(idx)
      }
      console.log("ar",arr)
     
      }
      

    const handleClose = () => {
      setGbr();
      setSimpan();
      setOpen(false);
    };

     const handleTambah = () => {
      //  console.log(simpan)
      const imgBody = new FormData();
      imgBody.append('file', simpan);
      //  console.log(file)
      fetch(UrlServer.address + 'api/v1/upload', {
        method: 'POST',
        headers: {
          'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt'
        },
        body: imgBody
      })
        .then(res => res.json())
        .then(res => {
          //  console.log('hasil', res);
          if (res.body == '-1') {
            swal({ title: 'Image gagal ditambahkan!', icon: 'error' });
          } else {
            swal({ title: 'Image berhasil ditambahkan', icon: 'success' }).then(
              value => {
                setSimpan();
                setOpen(false);
                fetchData();
              }
            );
          }
        });
    };

     const handleEdit = e => {
    var file = new File([simpan], e, { type: 'text/plain' });
    const imgBody = new FormData();
    imgBody.append('file', file);
    //  console.log(file)
    fetch(UrlServer.address + 'api/v1/upload', {
      method: 'POST',
      headers: {
        'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt'
      },
      body: imgBody
    })
      .then(res => res.json())
      .then(res => {
        if (res.body == '-1') {
          swal({ title: 'Image gagal diupdate!', icon: 'error' });
        } else {
          swal({ title: 'Image berhasil diupdate', icon: 'success' }).then(
            value => {
              setSimpan();
              window.location.reload();
            }
          );
        }
      });
  };
          
          const handleInputChange = e => {
            let img = e.target.files[0];  //Capture the file in variable otherwise event gets nullified and you won't get file.
            const objectUrl = URL.createObjectURL(img)
            setGbr(objectUrl)
            setSimpan(img)
          }

          const handleSearch = event => {
            if (event.target.value != '') {
              setFilter(
                data.filter(dt => {
                  return (
                    dt.toLowerCase().search(event.target.value.toLowerCase()) !== -1
                  );
                })
              );
            } else {
              fetchData();
            }
          };

  useEffect(() => {
    fetchData()
  }, [offset]);

  return (
   
<>
<Grid container spacing={2} className={classes.wrapper}>
<Grid item xs={6} md={11} >
    <SearchBar onSearch={handleSearch} />
    </Grid>
    <Grid item xs={6} md={1}>
    <Button variant='contained' color="primary" style={{width:'90%'}} onClick={()=>handleDialog()}>ADD</Button>
    </Grid>
    </Grid>
    <Page className={classes.page}>
        <Grid container style={{ marginTop: '50px' }}>
          {filter.map((dt, index) => (
            <Grid item xs={4} md={2} spacing={2}>
              <Card className={classes.root} key={index}>
                <CardActionArea onClick={() => handleDialog(dt)}>
                  <center>
                    <CardMedia
                      className={classes.media}
                      image={'https://server.bilas.id/' + dt}
                      title={dt}
                    />
                  </center>
                  <CardContent style={{ padding: 2 }}>
                    <center>
                      <Typography gutterBottom>
                        {dt.substring(0, 22)}
                      </Typography>
                    </center>
                  </CardContent>
                </CardActionArea>
                {/* <CardActions className={classes.actions} disableActionSpacing>
          <IconButton aria-label="Add to favorites" onClick={() => handleCheck(index)}>
            <Favorite color={'error'} />
          </IconButton>
          </CardActions> */}
                {/* <CardActions>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        'https://server.bilas.id/' + dt
                      );
                      setAlert(true);
                    }}>
                    Copy Url
                  </Button>
                </CardActions> */}
              </Card>
            </Grid>
          ))}
        </Grid>
        {/* <Snackbar
          open={alert}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={3000}
          onClose={() => setAlert(false)}>
          <Alert onClose={() => setAlert(false)} severity="success">
            URL Copied!
          </Alert>
        </Snackbar> */}
        <ReactPaginate
          previousLabel={'prev'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
        />
      </Page>

   <Dialog
        fullWidth={'md'}
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <MuiDialogTitle disableTypography>
          <Typography variant="h6">
            {dialog == 'no-image.png' ? '' : dialog}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item lg style={{ textAlign: 'center' }}>
              <center>
                <img
                  src={
                    simpan == undefined || simpan == ''
                      ? 'https://server.bilas.id/' + dialog
                      : gbr
                  }
                  style={{ width: '400px' }}
                />
              </center>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {dialog == 'no-image.png' ? (
            ''
          ) : (
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => handleDelete(dialog)}>
              Delete
            </Button>
          )}

          <Button
            variant="contained"
            component="label"
            color="primary"
            size="small"
            startIcon={<CloudUploadIcon />}>
            UPDATE
            <input type="file" onChange={handleInputChange} hidden />
          </Button>

          {dialog == 'no-image.png' ? (
            gbr == undefined ? (
              ''
            ) : (
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={handleTambah}>
                Tambah
              </Button>
            )
          ) : gbr == undefined ? (
            ''
          ) : (
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => handleEdit(dialog)}>
              Update
            </Button>
          )}

            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => onImage(dialog)}>
              Use Image
            </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
Img.propTypes = {
    onImage: PropTypes.func,
    formName: PropTypes.string
};

export default Img;