import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const GlobalStyles = makeStyles(theme => ({
  media: {
    height:150,
    width:400
  },
    page: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },
    flexContainer: {
      display: 'flex'
    },
    flexContainerCenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    flexContainerRight: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    flexContainerBottom: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'end'
    },
    flexOne: {
      flex: 1,
    },
    flexRow: {
      flexDirection: 'row',
    },
    flexColumn: {
      flexDirection: 'column',
    },
    setRight: {
      alignItems: 'right',
    },
    divider: {
      backgroundColor: theme.palette.divider,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    margin_all_5: {
      margin: '5px'
    },
    margin_t_10: {
      marginTop: '10px'
    },
    margin_l_10: {
      marginLeft: '10px'
    },
    margin_r_10: {
      marginRight: '10px'
    },
    margin_b_10: {
      marginBottom: '10px'
    },
    margin_side_20: {
      marginLeft: '20px',
      marginRight: '20px'
    },
    paddingContainer: {
      padding: theme.spacing(2)
    },
    buttonPrimary: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '13px',
        textTransform: 'uppercase',
        marginRight: '5px',
        minWidth: '80px',
        minHeight: '35px',
        border: '1px solid' + theme.palette.background.paper,
        borderRadius: '5px',
        color: '#FFFFFF',
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            boxShadow: '0 0 10px 0px rgba(63,63,68,0.05)'
        }
    },
    buttonSecondary: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '13px',
      textTransform: 'uppercase',
      marginRight: '5px',
      minWidth: '80px',
      minHeight: '35px',
      border: '1px solid' + theme.palette.background.paper,
      borderRadius: '5px',
      color: '#FFFFFF',
      backgroundColor: theme.palette.secondary.main,
      cursor: 'pointer',
      '&:hover': {
          backgroundColor: theme.palette.secondary.dark,
          boxShadow: '0 0 10px 0px rgba(63,63,68,0.05)'
      }
    },
    buttonError: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '13px',
      textTransform: 'uppercase',
      marginRight: '5px',
      minWidth: '80px',
      minHeight: '35px',
      border: '1px solid' + theme.palette.background.paper,
      borderRadius: '5px',
      color: '#FFFFFF',
      backgroundColor: theme.palette.error.main,
      cursor: 'pointer',
      '&:hover': {
          backgroundColor: theme.palette.error.dark,
          boxShadow: '0 0 10px 0px rgba(63,63,68,0.05)'
      }
    },
    buttonSuccess: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '13px',
      textTransform: 'uppercase',
      marginRight: '5px',
      minWidth: '80px',
      minHeight: '35px',
      border: '1px solid' + theme.palette.background.paper,
      borderRadius: '5px',
      color: '#FFFFFF',
      backgroundColor: colors.green[600],
      cursor: 'pointer',
      '&:hover': {
          backgroundColor: colors.green[900],
          boxShadow: '0 0 10px 0px rgba(63,63,68,0.05)'
      }
    },
    buttonDisable: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '13px',
      textTransform: 'uppercase',
      marginRight: '5px',
      minWidth: '80px',
      minHeight: '35px',
      border: '1px solid' + theme.palette.background.paper,
      borderRadius: '5px',
      color: '#FFFFFF',
      backgroundColor: colors.grey[600],
      cursor: 'pointer',
      '&:hover': {
          backgroundColor: colors.grey[900],
          boxShadow: '0 0 10px 0px rgba(63,63,68,0.05)'
      }
    },
    avatarOutline: {
      border: `1px solid `+ theme.palette.background.paper
    },
    textNoneStyle: {
      textTransform: 'none'
    },
    dotSmall: {
      height: '15px',
      minWidth: '15px',
    },

}));

export default GlobalStyles;