import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { Popover, CardHeader, CardActions, Divider, Button, colors, Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import MessageIcon from '@material-ui/icons/Email';
import StoreIcon from '@material-ui/icons/Store';
import gradients from 'utils/gradients';
import useRouter from 'utils/useRouter';
import { Context } from 'context';

const useStyles = makeStyles(theme => ({
  root: {
    width: 350,
    maxWidth: '100%'
  },
  actions: {
    // backgroundColor: colors.grey[50],
    justifyContent: 'center'
  },
  empty: {
    textAlign: 'center',
    padding: theme.spacing(3)
  },
  image: {
    height: 240,
    backgroundImage: 'url("/images/undraw_empty_xct9.svg")',
    backgroundPositionX: 'right',
    backgroundPositionY: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  listItemDisabled: {
    backgroundColor: theme.palette.divider,
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  avatarBlue: {
    backgroundImage: gradients.blue
  },
  avatarGreen: {
    backgroundImage: gradients.green
  },
  avatarOrange: {
    backgroundImage: gradients.orange
  },
  avatarIndigo: {
    backgroundImage: gradients.indigo
  },
  arrowForwardIcon: {
    color: theme.palette.icon
  }
}));

const NotificationsPopover = props => {
  const { notifications, anchorEl, ...rest } = props;
  const { history } = useRouter();
  const [state, dispatch] = React.useContext(Context);
  const classes = useStyles();

  const avatars = {
    order: (
      <Avatar className={classes.avatarBlue}><PaymentIcon /></Avatar>
    ),
    livechat: (
      <Avatar className={classes.avatarOrange}><MessageIcon /></Avatar>
    ),
    outlet: (
      <Avatar className={classes.avatarGreen}><StoreIcon /></Avatar>
    ),
    feedback: (
      <Avatar className={classes.avatarIndigo}><PeopleIcon /></Avatar>
    )
  };

  const handleNotif = (event) => {
    if (event.type === 'livechat') {
      history.push('/livechat')
    } else if (event.type === 'feedback') {
      history.push('/feedback/masukkan')
    } else if (event.type === 'order') {
      history.push('/withdrawal')
    } else if (event.type === 'preorder') {
      history.push('/penjualan/preorder')
    } 

    notifications.forEach(element => {
      if (event.id === element.id) {
        event.status = false
        return
      }
    });

    var z = notifications.filter((x)=> x.id!=event.id)

    dispatch({
      type: 'NOTIF',
      content: z
    })
    console.log('notif baca ->', z)
    localStorage.setItem('notifData', JSON.stringify(z))
  }

  return (
    <Popover {...rest} anchorEl={anchorEl} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}>
      <div className={classes.root}>
        <CardHeader title="Notifications" />
        <Divider />
        {notifications.length > 0 ? (
          <List disablePadding>
            {notifications.map((notification, i) => (
              <ListItem
                onClick={() => handleNotif(notification)}
                className={notification.status ? classes.listItem : classes.listItemDisabled}
                component={RouterLink}
                divider={i < notifications.length - 1}
                key={notification.id}
                to="#"
              >
                <ListItemAvatar>{avatars[notification.type]}</ListItemAvatar>
                <ListItemText
                  primary={notification.title}
                  primaryTypographyProps={{ variant: 'body1' }}
                  secondary={moment(notification.waktu).fromNow()}
                />
                <ArrowForwardIcon className={classes.arrowForwardIcon} />
              </ListItem>
            ))}
          </List>
        ) : (
            <div className={classes.empty}>
              <div className={classes.image} />
              <Typography variant="h4">There's nothing here...</Typography>
            </div>
          )}
        <Divider />
        <CardActions className={classes.actions}>
          <Button component={RouterLink} size="small" to="#">
            See all
          </Button>
        </CardActions>
      </div>
    </Popover>
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default NotificationsPopover;
