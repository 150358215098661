import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  IconButton,
  Input,
  Tooltip
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
// import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
// import AttachFileIcon from '@material-ui/icons/AttachFile';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2)
  },
  paper: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5, 2)
  },
  input: {
    width: '100%'
  },
  divider: {
    width: 1,
    height: 24
  },
  fileInput: {
    display: 'none'
  }
}));

const ChatListInput = props => {
  const { className, onSending, onTyping, value, ...rest } = props;
  const classes = useStyles();
  // const fileInputRef = useRef(null);

  // const handleAttach = () => {
  //   fileInputRef.current.click();
  // };

  const handleEnter = (e) => {
    if (e.keyCode == 13) {
      onSending()
    }
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Input
        className={classes.input}
        disableUnderline
        onKeyDown={handleEnter}
        onChange={onTyping}
        value={value}
        placeholder="Leave a message"
      />
      <Tooltip title="Send">
        <IconButton onClick={onSending}>
          <SendIcon />
        </IconButton>
      </Tooltip>
      {/* <Divider className={classes.divider} />
      <Tooltip title="Attach photo">
        <IconButton
          edge="end"
          onClick={handleAttach}
        >
          <AddPhotoIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Attach file">
        <IconButton
          edge="end"
          onClick={handleAttach}
        >
          <AttachFileIcon />
        </IconButton>
      </Tooltip>
      <input
        className={classes.fileInput}
        ref={fileInputRef}
        type="file"
      /> */}
    </div>
  );
};

ChatListInput.propTypes = {
  className: PropTypes.string,
  onSending: PropTypes.func,
  onTyping: PropTypes.func,
  typing: PropTypes.string
};

export default ChatListInput;
