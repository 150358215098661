/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import StoreIcon from '@material-ui/icons/StorefrontOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import OwnerIcon from '@material-ui/icons/AssignmentIndOutlined';
import HelpIcon from '@material-ui/icons/HelpOutline';
import LocalOffer from '@material-ui/icons/LocalOfferOutlined';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import VoucherIcon from '@material-ui/icons/LocalActivityOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import ViewCarouselOutlinedIcon from '@material-ui/icons/ViewCarouselOutlined';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import Localmall from '@material-ui/icons/LocalMallOutlined';
import DirectionsRun from '@material-ui/icons/DirectionsRunOutlined';
import ManageIcon from '@material-ui/icons/AllInclusive';
import ReferalIcon from '@material-ui/icons/WifiTetheringOutlined';
import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturnedOutlined';
import AdjustOutlinedIcon from '@material-ui/icons/AdjustOutlined';
import CardGiftcardOutlinedIcon from '@material-ui/icons/CardGiftcardOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import TouchAppOutlinedIcon from '@material-ui/icons/TouchAppOutlined';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PaymentIcon from '@material-ui/icons/Payment';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import ImageIcon from '@material-ui/icons/Image';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import CameraFrontIcon from '@material-ui/icons/DateRangeOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PhoneIphoneIcon from '@material-ui/icons/AddToHomeScreenOutlined';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Timeline from '@material-ui/icons/Timeline';
import LinkIcon from '@material-ui/icons/Link';
import EventIcon from '@material-ui/icons/Event';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import EmojiNatureIcon from '@material-ui/icons/EmojiNature';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import BuildIcon from '@material-ui/icons/Build';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AllInboxIcon from '@material-ui/icons/AllInbox';

export const Writer = [
  {
    title: 'Menu',
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: DashboardIcon
      },
      {
        title: 'Post Saya',
        href: '/blog/post',
        icon: Localmall
      },
      {
        title: 'Buat Post',
        href: '/blog/post/form',
        icon: Localmall
      },
      {
        title: 'Komentar',
        href: '/comment',
        icon: Localmall
      },
    ]
    }
]

export const Partner = [
  {
    title: 'Menu',
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: Localmall
      },
      {
        title: 'Pembuatan Voucher',
        href: '/voucher/request',
        icon: PostAddOutlinedIcon
      },
      // {
      //   title: 'Pembuatan Voucher',
      //   href: '/voucher/pembuatan',
      //   icon: PostAddOutlinedIcon
      // },
      {
        title: 'Data Voucher',
        href: '/voucher/data',
        icon: VoucherIcon
      },
      {
        title: 'Pemakaian Voucher',
        href: '/voucher/pemakaian',
        icon: TouchAppOutlinedIcon,
      },
      {
        title: 'Laporan',
        href: '/voucher/laporan',
        icon: AssignmentOutlinedIcon
      },
      {
        title: 'Data Outlet',
        href: '/outlet/daftar-outlet',
        icon: StoreIcon,
      }
    ]
    }
]

export const Partner2 = [
  {
    title: 'Menu',
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: Localmall
      },
      {
        title: 'Data Referal',
        href: '/sales/datareferal',
        icon: TimelineOutlinedIcon
      },
      {
        title: 'Riwayat',
        href: '/partner/saldo/riwayat',
        icon: TimelineOutlinedIcon
      },
      {
        title: 'Pengaturan Bank',
        href: '/partner/banksetting',
        icon: AccountBalanceWalletIcon
      },
      {
        title: 'Withdraw',
        href: '/partner/withdraw',
        icon: PaymentIcon
      },
    ]
    }
]

export const Sales = [
  {
    title: 'Menu',
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: Localmall
      },
      {
        title: 'Progres',
        href: '/progress',
        icon: TimelineOutlinedIcon
      },
      {
        title: 'Data Referal',
        href: '/sales/datareferal',
        icon: TimelineOutlinedIcon
      },
      {
        title: 'JOB',
        icon: TimelineOutlinedIcon,
        children: [
          {
            title: 'Data Job',
            href: '/sales/job',
          },
          {
            title: 'Job Diterma',
            href: '/sales/job/approve',
          },
          {
            title: 'Job Ditolak',
            href: '/sales/job/reject',
          },
        ]
      },
      {
        title: 'Riwayat',
        href: '/sales/saldo/riwayat',
        icon: TimelineOutlinedIcon
      },
      {
        title: 'Pengaturan Bank',
        href: '/sales/banksetting',
        icon: AccountBalanceWalletIcon
      },
      {
        title: 'Withdraw',
        href: '/sales/withdraw',
        icon: PaymentIcon
      },
    ]
    }
]

export const SalesMobile = [
  {
    title: 'Menu',
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: Localmall
      },
      {
        title: 'Progres',
        icon: TimelineOutlinedIcon,
        children: [
          {
            title: 'Kunjungan',
            href: '/progress/kunjungan',
          },
          {
            title: 'Trial',
            href: '/progress/trial',
          },
          {
            title: 'Follow Up',
            href: '/progress/followup',
          },
          {
            title: 'Aktivasi',
            href: '/progress/aktivasi',
          }
        ]
      },
      {
        title: 'Data Referal',
        href: '/sales/datareferal',
        icon: TimelineOutlinedIcon
      },
      {
        title: 'JOB',
        icon: TimelineOutlinedIcon,
        children: [
          {
            title: 'Data Job',
            href: '/sales/job',
          },
          {
            title: 'Job Diterma',
            href: '/sales/job/approve',
          },
          {
            title: 'Job Ditolak',
            href: '/sales/job/reject',
          },
        ]
      },
      {
        title: 'Riwayat',
        href: '/sales/saldo/riwayat',
        icon: TimelineOutlinedIcon
      },
      {
        title: 'Pengaturan Bank',
        href: '/sales/banksetting',
        icon: AccountBalanceWalletIcon
      },
      {
        title: 'Withdraw',
        href: '/sales/withdraw',
        icon: PaymentIcon
      },
    ]
    }
]

// export const Keuangan = [
//   {
//     title: 'Menu',
//     pages: [
//       {
//         title: 'Dashboard',
//         href: '/dashboard',
//         icon: DashboardIcon
//       },
//       {
//         title: 'All Summary',
//         href: '/ringkasan',
//         icon: Timeline
//       },
//       {
//         title: 'Daftar Outlet',
//         href: '/outlet/daftar-outlet',
//         icon: StoreIcon
//       },
//       {
//         title: 'Order',
//         icon: AssignmentReturnedIcon,
//         children: [
//           {
//             title: 'Ringkasan',
//             href: '/ringkasan_order',
//           },
//           {
//             title: 'Data Order',
//             href: '/daftar_order',
//           },
//           {
//             title: 'Laporan',
//             href: '/laporan_order',
//           },
//         ]
//       },
//       {
//         title: 'Voucher',
//         href: '/voucher',
//         icon: VoucherIcon,
//         children: [
//           {
//             title: 'Ringkasan',
//             href: '/voucher/ringkasan'
//           },
//           {
//             title: 'Data Voucher',
//             href: '/voucher/data'
//           },
//           {
//             title: 'List Pemakaian',
//             href: '/voucher/pemakaian'
//           },
//           {
//             title: 'Laporan Pemakaian',
//             href: '/voucher/laporan'
//           },
//           {
//             title: 'Laporan Pendapatan',
//             href: '/voucher/laporan'
//           },
//         ]
//       },
     
//     ]
//     }
// ]

export const SalesOfficer = [
  {
    title: 'Menu Sales Officer',
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: DashboardIcon
      },
      {
        title: 'Broadcast',
        href: '/broadcast',
        icon: ManageIcon,
      },
      {
        title: 'Event',
        href: '/event',
        icon: EventIcon
      },
      {
        title: 'DropBox',
        href: '/dropbox',
        icon: AllInboxIcon
      },
      {
        title: 'Approve Cashback',
        href: '/voucher/approval',
        icon: VoucherIcon
      },
      {
        title: 'Order',
        icon: AssignmentReturnedIcon,
        children: [
          // {
          //   title: 'Ringkasan',
          //   href: '/ringkasan_order',
          // },
          {
            title: 'Data Order',
            href: '/daftar_order',
          },
          // {
          //   title: 'Laporan',
          //   href: '/laporan_order',
          // },
          {
            title: 'Repeat Order',
            href: '/repeat_order',
          },
        ]
      },
      {
        title: 'Outlet',
        href: '/outlet',
        icon: StoreIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/outlet/ringkasan-outlet',
          },
          {
            title: 'Data Outlet',
            href: '/outlet/daftar-outlet',
          },
          {
            title: 'Max Antrian',
            href: '/outlet/antrian-outlet',
          },
          { 
            title: 'Perpanjangan Masa Trial',
            href: '/outlet/extendtrial'
          },
          {
            title: 'Outlet Aktif',
            href: '/outlet/outlet-aktif',
          },
          {
            title: 'Laporan',
            href: '/outlet/laporan'
          },
          // {
          //   title: 'Bonus Masa Aktif',
          //   href: '/outlet/bonus',
          // },
          {
            title: 'Data Pemilik',
            href: '/outlet/owner',
          },
          {
            title: 'Outlet Archive',
            href: '/outlet/outlet-archive',
          },
          {
            title: 'Upgrade / Downgrade',
            href: '/outlet/outlet-upgrade',
          },
          {
            title: 'Trial Bisnis',
            href: '/outlet/trialbisnis'
          }
        ]
      },
      {
        title: 'Blaztap Order',
        href: '/manufacture',
        icon: PostAddIcon,
        children: [
          {
            title: 'Order Blaztap',
            href: '/blaztap/order_blaztap'
          },
          {
            title: 'Barang Keluar',
            href: '/blaztap/barang_keluar'
          },
          {
            title: 'Laporan Penjualan',
            href: '/blaztap/laporan'
          }
        ]
      },
      {
        title: 'Penjualan',
        href: '/penjualan',
        icon: ShoppingCartIcon,
        children: [
          // {
          //   title: 'Ringkasan',
          //   href: '/penjualan/ringkasan',
          // },
          {
            title: 'Buat Invoice',
            href: '/penjualan/invoice',
          },
          {
            title: 'Daftar Product',
            href: '/penjualan/produk/retail',
          },
          {
            title: 'Data Order',
            href: '/penjualan/data_order',
          },
          {
            title: 'Pre-Order Mitra',
            href: '/penjualan/preorder',
          },
          {
            title: 'Product in Cart',
            href: '/penjualan/onchart'
          },
          {
            title: 'Top Product View',
            href: '/penjualan/topproductview'
          }
        ]
      },
      {
        title: 'Banner',
        href: '/banner',
        icon: ViewCarouselOutlinedIcon,
        children: [
          {
            title: 'Banner App',
            href: '/banner',
          },
          {
            title: 'Banner Promosi',
            href: '/banner/promosi',
          }
        ]
      },
      {
        title: 'Beta Tester',
        // href: '/outlet',
        icon: EmojiNatureIcon,
        children: [
          {
            title: 'Pendaftaran',
            href: '/betatester/pendaftaran',
          },
          {
            title: 'Data Betatester',
            href: '/betatester/data',
          },
          {
            title: 'Laporan Bug',
            href: '/betatester/bug',
          },
          {
            title: 'Report',
            href: '/betatester/report',
          },
         
        ]
      },
      // {
      //   title: 'Prospek Customer',
      //   href: '/prospek',
      //   icon: GroupAddIcon,
      //   children: [
      //     {
      //       title: 'Ringkasan',
      //       href: '/prospek/ringkasan'
      //     },
      //     {
      //       title: 'Data Batch',
      //       href: '/prospek/batch',
      //     },
      //     {
      //       title: 'Data Prospek',
      //       href: '/prospek',
      //     },
      //     {
      //       title: 'Prospek Sukses',
      //       href: '/prospek/sukses'
      //     }
      //   ]
      // },
      {
        title: 'Customer Journey',
        href: '/prospek_new',
        icon: GroupAddIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/prospek_new/ringkasan'
          },
          // {
          //   title: 'Data Batch',
          //   href: '/prospek_new/batch',
          // },
          {
            title: 'Data Prospek',
            href: '/prospek_new',
          },
          {
            title: 'Klaim Prospek',
            href: '/prospek_new/klaim'
          }
        ]
      },
      {
        title: 'Jadwal Training',
        href: '/jadwaltraining',
        icon: CameraFrontIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/jadwaltraining/ringkasan',
          },
          {
            title: 'Training Virtual',
            href: '/jadwaltraining/trainingvirtual',
          },
          {
            title: 'Training On Location',
            href: '/jadwaltraining/trainingonlocation',
          },
          // {
          //   title: 'Dikonfirmasi & Pending',
          //   href: '/jadwaltraining',
          // },
          {
            title: 'Selesai',
            href: '/jadwaltraining/selesai',
          },
          {
            title: 'Dibatalkan',
            href: '/jadwaltraining/dibatalkan',
          }
        ]
      },
      {
        title: 'Referensi Harga',
        href: '/referensiharga',
        icon: LocalAtmIcon
      },
      {
        title: 'Support',
        href: '/support',
        icon: HelpIcon,
        children: [
          {
            title: 'F. A. Q',
            href: '/support/faq'
          },
          {
            title: 'Help Center',
            href: '/support/help'
          },
          {
            title: 'Help Kategori',
            href: '/support/help_kategori'
          },
          {
            title: 'Vidio Kategori',
            href: '/support/vidio_kategori'
          },
          {
            title: 'Privacy Police',
            href: '/support/privacy'
          },
          {
            title: 'Term of Condition',
            href: '/support/toc'
          },
        ]
      },
      {
        title: 'Feedback',
        href: '/feedback',
        icon: ListAltIcon,
        children: [
          {
            title: 'Masukkan Pengguna',
            href: '/feedback/masukkan',
          },
          {
            title: 'Testimoni Pengguna',
            href: '/feedback/testimoni',
          }, 
          {
            title: 'Vidio',
            href:'/feedback/vidio'
          },
          {
            title: 'Laporan Bug',
            href: '/feedback/bug',
          }
        ]
      },
      // {
      //   title: 'Setting',
      //   href: '/setting',
      //   icon: SettingsIcon,
      // },
    ]
    }
]

export const CS = [
  {
    title: 'Menu CS',
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: DashboardIcon
      },
      {
        title: 'Broadcast',
        href: '/broadcast',
        icon: ManageIcon,
      },
      {
        title: 'Event',
        href: '/event',
        icon: EventIcon
      },
      {
        title: 'DropBox',
        href: '/dropbox',
        icon: AllInboxIcon
      },
      {
        title: 'Approve Cashback',
        href: '/voucher/approval',
        icon: VoucherIcon
      },
      // {
      //   title: 'Order',
      //   icon: AssignmentReturnedIcon,
      //   children: [
      //     {
      //       title: 'Ringkasan',
      //       href: '/ringkasan_order',
      //     },
      //     {
      //       title: 'Data Order',
      //       href: '/daftar_order',
      //     },
      //     // {
      //     //   title: 'Laporan',
      //     //   href: '/laporan_order',
      //     // },
      //     {
      //       title: 'Repeat Order',
      //       href: '/repeat_order',
      //     },
      //   ]
      // },
      {
        title: 'Outlet',
        href: '/outlet',
        icon: StoreIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/outlet/ringkasan-outlet',
          },
          {
            title: 'Data Outlet',
            href: '/outlet/daftar-outlet',
          },
          {
            title: 'Max Antrian',
            href: '/outlet/antrian-outlet',
          },
          {
            title: 'Outlet Aktif',
            href: '/outlet/outlet-aktif',
          },
          // {
          //   title: 'Laporan',
          //   href: '/outlet/laporan'
          // },
          // {
          //   title: 'Bonus Masa Aktif',
          //   href: '/outlet/bonus',
          // },
          {
            title: 'Data Pemilik',
            href: '/outlet/owner',
          },
          {
            title: 'Outlet Archive',
            href: '/outlet/outlet-archive',
          },
          {
            title: 'Upgrade / Downgrade',
            href: '/outlet/outlet-upgrade',
          }
          // {
          //   title: 'Trial Bisnis',
          //   href: '/outlet/trialbisnis'
          // }
        ]
      },
      {
        title: 'Banner',
        href: '/banner',
        icon: ViewCarouselOutlinedIcon,
        children: [
          {
            title: 'Banner App',
            href: '/banner',
          },
          {
            title: 'Banner Promosi',
            href: '/banner/promosi',
          }
        ]
      },
      {
        title: 'Blaztap Order',
        href: '/manufacture',
        icon: PostAddIcon,
        children: [
          {
            title: 'Order Blaztap',
            href: '/blaztap/order_blaztap'
          },
          {
            title: 'Barang Keluar',
            href: '/blaztap/barang_keluar'
          },
          {
            title: 'Laporan Penjualan',
            href: '/blaztap/laporan'
          }
        ]
      },
      {
        title: 'Penjualan',
        href: '/penjualan',
        icon: ShoppingCartIcon,
        children: [
          // {
          //   title: 'Ringkasan',
          //   href: '/penjualan/ringkasan',
          // },
          {
            title: 'Buat Invoice',
            href: '/penjualan/invoice',
          },
          {
            title: 'Daftar Product',
            href: '/penjualan/produk/retail',
          },
          {
            title: 'Data Order',
            href: '/penjualan/data_order',
          },
          {
            title: 'Pre-Order Mitra',
            href: '/penjualan/preorder',
          },
          {
            title: 'Product in Cart',
            href: '/penjualan/onchart'
          },
          {
            title: 'Top Product View',
            href: '/penjualan/topproductview'
          }
        ]
      },
      {
        title: 'Request Aplikasi',
        href: '/outletaktivasi',
        icon: PhoneIphoneIcon,
        children: [
          {
            title: 'Draf',
            href: '/outletaktivasi/draf'
          },
          {
            title: 'Proses',
            href: '/outletaktivasi',
          }
        ]
      },
      {
        title: 'Beta Tester',
        // href: '/outlet',
        icon: EmojiNatureIcon,
        children: [
          {
            title: 'Pendaftaran',
            href: '/betatester/pendaftaran',
          },
          {
            title: 'Data Betatester',
            href: '/betatester/data',
          },
          {
            title: 'Laporan Bug',
            href: '/betatester/bug',
          },
          {
            title: 'Report',
            href: '/betatester/report',
          },
         
        ]
      },
      // {
      //   title: 'Prospek Customer',
      //   href: '/prospek',
      //   icon: GroupAddIcon,
      //   children: [
      //     {
      //       title: 'Ringkasan',
      //       href: '/prospek/ringkasan'
      //     },
      //     {
      //       title: 'Data Batch',
      //       href: '/prospek/batch',
      //     },
      //     {
      //       title: 'Data Prospek',
      //       href: '/prospek',
      //     },
      //     {
      //       title: 'Prospek Sukses',
      //       href: '/prospek/sukses'
      //     }
      //   ]
      // },
      {
        title: 'Customer Journey',
        href: '/prospek_new',
        icon: GroupAddIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/prospek_new/ringkasan'
          },
          // {
          //   title: 'Data Batch',
          //   href: '/prospek_new/batch',
          // },
          {
            title: 'Data Prospek',
            href: '/prospek_new',
          },
          {
            title: 'Klaim Prospek',
            href: '/prospek_new/klaim'
          }
        ]
      },
      {
        title: 'Jadwal Training',
        href: '/jadwaltraining',
        icon: CameraFrontIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/jadwaltraining/ringkasan',
          },
          {
            title: 'Training Virtual',
            href: '/jadwaltraining/trainingvirtual',
          },
          {
            title: 'Training On Location',
            href: '/jadwaltraining/trainingonlocation',
          },
          // {
          //   title: 'Dikonfirmasi & Pending',
          //   href: '/jadwaltraining',
          // },
          {
            title: 'Selesai',
            href: '/jadwaltraining/selesai',
          },
          {
            title: 'Dibatalkan',
            href: '/jadwaltraining/dibatalkan',
          }
        ]
      },
      // {
      //   title: 'Referensi Harga',
      //   href: '/referensiharga',
      //   icon: LocalAtmIcon
      // },
      {
        title: 'Support',
        href: '/support',
        icon: HelpIcon,
        children: [
          {
            title: 'F. A. Q',
            href: '/support/faq'
          },
          {
            title: 'Help Center',
            href: '/support/help'
          },
          {
            title: 'Help Kategori',
            href: '/support/help_kategori'
          },
          {
            title: 'Vidio Kategori',
            href: '/support/vidio_kategori'
          },
          {
            title: 'Privacy Police',
            href: '/support/privacy'
          },
          {
            title: 'Term of Condition',
            href: '/support/toc'
          },
        ]
      },
      {
        title: 'Feedback',
        href: '/feedback',
        icon: ListAltIcon,
        children: [
          {
            title: 'Masukkan Pengguna',
            href: '/feedback/masukkan',
          },
          {
            title: 'Testimoni Pengguna',
            href: '/feedback/testimoni',
          },
          {
            title: 'Vidio',
            href:'/feedback/vidio'
          },
          {
            title: 'Laporan Bug',
            href: '/feedback/bug',
          }
        ]
      },
      // {
      //   title: 'Setting',
      //   href: '/setting',
      //   icon: SettingsIcon,
      // },
    ]
    }
]

export const admin = [
  {
    title: '',
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: DashboardIcon
      },
      {
        title: 'All Summary',
        href: '/ringkasan',
        icon: Timeline
      },
      {
        title: 'Outlet',
        href: '/outlet',
        icon: StoreIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/outlet/ringkasan-outlet',
          },
          {
            title: 'Data Outlet',
            href: '/outlet/daftar-outlet',
          },
          {
            title: 'Max Antrian',
            href: '/outlet/antrian-outlet',
          },
          {
            title: 'Outlet Aktif',
            href: '/outlet/outlet-aktif',
          },
          {
            title: 'Laporan',
            href: '/outlet/laporan',
          },
          {
            title: 'Bonus Masa Aktif',
            href: '/outlet/bonus',
          },
          {
            title: 'Data Pemilik',
            href: '/outlet/owner',
          },
          {
            title: 'Outlet Archive',
            href: '/outlet/outlet-archive',
          },
          {
            title: 'Upgrade / Downgrade',
            href: '/outlet/outlet-upgrade',
          },
          { 
            title: 'Perpanjangan Masa Trial',
            href: '/outlet/extendtrial'
          },
          { 
            title: 'WA Bisnis',
            href: '/outlet/wa_bisnis'
          }
        ]
      },
      {
        title: 'Beta Tester',
        // href: '/outlet',
        icon: EmojiNatureIcon,
        children: [
          {
            title: 'Pendaftaran',
            href: '/betatester/pendaftaran',
          },
          {
            title: 'Data Betatester',
            href: '/betatester/data',
          },
          {
            title: 'Laporan Bug',
            href: '/betatester/bug',
          },
          {
            title: 'Report',
            href: '/betatester/report',
          },
         
        ]
      },
      {
        title: 'Order',
        icon: AssignmentReturnedIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/ringkasan_order',
          },
          {
            title: 'Data Order',
            href: '/daftar_order',
          },
          {
            title: 'Laporan',
            href: '/laporan_order',
          },
          {
            title: 'Repeat Order',
            href: '/repeat_order',
          },
        ]
      },
      {
        title: 'Poin',
        icon: AdjustOutlinedIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/poin',
          },
          {
            title: 'Poin Outlet',
            href: '/poin/outlet',
          },
          {
            title: 'Riwayat',
            href: '/poin/riwayat',
          },
          {
            title: 'Pengaturan',
            href: '/poin/pengaturan',
          }
        ]
      },
      {
        title: 'Promosi',
        icon: LocalOffer,
        children: [
          {
            title: 'Ringkasan',
            href: '/promo',
          },
          {
            title: 'Data Promo',
            href: '/promo/data',
          },
        ]
      },
      {
        title: 'Referal',
        href: '/referal',
        icon: ReferalIcon,
        children: [
          {
            title: 'Data Referal',
            href: '/referal/data'
          },
          {
            title: 'List Pemakaian',
            href: '/referal/pemakaian'
          },
          {
            title: 'Pengaturan',
            href: '/referal/pengaturan'
          },
        ]
      },
      {
        title: 'Voucher',
        href: '/voucher',
        icon: VoucherIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/voucher/ringkasan'
          },
          {
            title: 'Pembuatan',
            href: '/voucher/pembuatan'
          },
          // {
          //   title: 'Request',
          //   href: '/voucher/request'
          // },
          {
            title: 'Approve Cashback',
            href: '/voucher/approval'
          },
          {
            title: 'Data Voucher',
            href: '/voucher/data'
          },
          {
            title: 'List Pemakaian',
            href: '/voucher/pemakaian'
          },
          {
            title: 'Laporan Pemakaian',
            href: '/voucher/laporan'
          },
          {
            title: 'Laporan Pendapatan',
            href: '/voucher/laporan'
          },
        ]
      },
      {
        title: 'QRIS',
        icon: DeveloperModeIcon,
        children: [
          {
            title: 'Aktivasi',
            href: '/qris/aktivasi',
          },
          {
            title: 'Riwayat',
            href: '/qris/histori',
          },
        ]
      },
      {
        title: 'Misi & Tantangan',
        href: '/mission',
        icon: CardGiftcardOutlinedIcon,
        children: [
          {
            title: 'Buat Baru',
            href: '/mission/data'
          },
          {
            title: 'Top Participant',
            href: '/mission/participant'
          },
          {
            title: 'Validasi Poin',
            href: '/mission/validasi'
          },
        ]
      },
      {
        title: 'Request Aplikasi',
        href: '/outletaktivasi',
        icon: PhoneIphoneIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/outletaktivasi/ringkasan'
          },
          {
            title: 'Draf',
            href: '/outletaktivasi/draf'
          },
          {
            title: 'Proses',
            href: '/outletaktivasi',
          },
          {
            title: 'Publish',
            href: '/outletaktivasi/publish'
          },
          {
            title: 'Pembayaran',
            href: '/outletaktivasi/pembayaran'
          },
          {
            title: 'Upgrade',
            href: '/outletaktivasi/upgrade'
          },
          {
            title: 'Laporan',
            href: '/outletaktivasi/laporan'
          }
        ]
      },
      // {
      //   title: 'Prospek Pelanggan',
      //   href: '/prospek',
      //   icon: GroupAddIcon,
      //   children: [
      //     {
      //       title: 'Ringkasan',
      //       href: '/prospek/ringkasan'
      //     },
      //     {
      //       title: 'Data Batch',
      //       href: '/prospek/batch',
      //     },
      //     {
      //       title: 'Data Prospek',
      //       href: '/prospek',
      //     },
      //     {
      //       title: 'Prospek Sukses',
      //       href: '/prospek/sukses'
      //     },
      //     {
      //       title: 'Performa',
      //       href: '/prospek/performa'
      //     }
      //   ]
      // },
      {
        title: 'Customer Journey',
        href: '/prospek_new',
        icon: GroupAddIcon,
        children: [
          // {
          //   title: 'Ringkasan',
          //   href: '/prospek_new/ringkasan'
          // },
          // {
          //   title: 'Data Batch',
          //   href: '/prospek_new/batch',
          // },
          {
            title: 'Data Prospek',
            href: '/prospek_new',
          },
          {
            title: 'Klaim Prospek',
            href: '/prospek_new/klaim'
          },
          {
            title: 'Performa CS',
            href: '/prospek_new/performa_cs'
          },
          {
            title: 'Performa Sales',
            href: '/prospek_new/performa_sales'
          }
        ]
      },
      {
        title: 'Penjualan',
        href: '/penjualan',
        icon: ShoppingCartIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/penjualan/ringkasan',
          },
          {
            title: 'Buat Invoice',
            href: '/penjualan/invoice',
          },
          {
            title: 'Daftar Product',
            href: '/penjualan/produk/retail',
          },
          {
            title: 'Data Order',
            href: '/penjualan/data_order',
          },
          {
            title: 'Pre-Order Mitra',
            href: '/penjualan/preorder',
          },
          {
            title: 'Product in Cart',
            href: '/penjualan/onchart'
          },
          {
            title: 'Top Product View',
            href: '/penjualan/topproductview'
          }
          // {
          //   title: 'Ringkasan',
          //   href: '/penjualan/ringkasan',
          // },
          // {
          //   title: 'Atur Ongkir',
          //   href: '/penjualan/ongkir',
          // },
          // {
          //   title: 'Produk Retail',
          //   href: '/penjualan/produk/retail',
          // },
          // {
          //   title: 'Pending Order',
          //   href: '/penjualan/pendingorder'
          // },
          // {
          //   title: 'Konfirmasi Order',
          //   href: '/penjualan/konfirmasiorder'
          // },
          // {
          //   title: 'In Proses',
          //   href: '/penjualan/inproses'
          // },
          // {
          //   title: 'Finished Order',
          //   href: '/penjualan/selesai'
          // },
          // {
          //   title: 'Product in Cart',
          //   href: '/penjualan/onchart'
          // },
          // {
          //   title: 'Top Product View',
          //   href: '/penjualan/topproductview'
          // }
        ]
      },
      {
        title: 'Withdrawal',
        href: '/withdrawal',
        icon: MonetizationOnIcon,
        children: [
          // {
          //   title: 'Ringkasan',
          //   href: '/prospek_new/ringkasan'
          // },
          {
            title: 'WD Pending',
            href: '/withdrawal',
          },
          {
            title: 'WD Proses',
            href: '/withdrawal/proses',
          },
          {
            title: 'WD Sukses',
            href: '/withdrawal/sukses'
          },
          {
            title: 'WD Reject',
            href: '/withdrawal/batal'
          }
        ]
      },
      {
        title: 'Blaztap Order',
        href: '/manufacture',
        icon: PostAddIcon,
        children: [
          {
            title: 'Order Blaztap',
            href: '/blaztap/order_blaztap'
          },
          {
            title: 'Barang Keluar',
            href: '/blaztap/barang_keluar'
          },
          {
            title: 'Laporan Penjualan',
            href: '/blaztap/laporan'
          }
        ]
      },
      {
        title: 'Blaztap MFG',
        href: '/manufacture',
        icon: BuildIcon,
        children: [
          {
            title: 'Bahan Baku',
            href: '/blaztap/bahan_baku'
          },
          {
            title: 'Daftar BOM',
            href: '/blaztap/bom',
          },
          {
            title: 'Pembelian Bahan Baku',
            href: '/blaztap/pembelian_bahan',
          },
          {
            title: 'Manufacture',
            href: '/blaztap/manufacture'
          },
          {
            title: 'Serial Number',
            href: '/blaztap/serial_number'
          },
          {
            title: 'Stock Product',
            href: '/blaztap/stock_product'
          }
        ]
      },
      {
        title: 'Event',
        href: '/event',
        icon: EventIcon
      },
      {
        title: 'Referensi Harga',
        href: '/referensiharga',
        icon: LocalAtmIcon
      },
    ]
  },
  {
    title: 'Management',
    pages: [
      {
        title: 'DropBox',
        href: '/dropbox',
        icon: AllInboxIcon
      },
      {
        title: 'Banner',
        href: '/banner',
        icon: ViewCarouselOutlinedIcon,
        children: [
          {
            title: 'Banner App',
            href: '/banner',
          },
          {
            title: 'Banner Promosi',
            href: '/banner/promosi',
          }
        ]
      },
      {
        title: 'Broadcast',
        href: '/broadcast',
        icon: MailIcon,
      },
      {
        title: 'Auto Broadcast',
        href: '/autobroadcast',
        icon: AutorenewIcon,
      },
      {
        title: 'Gallery',
        href: '/images',
        icon: ImageIcon,
      },
      {
        title: 'Jadwal Training',
        href: '/jadwaltraining',
        icon: CameraFrontIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/jadwaltraining/ringkasan',
          },
          {
            title: 'Training Virtual',
            href: '/jadwaltraining/trainingvirtual',
          },
          {
            title: 'Training On Location',
            href: '/jadwaltraining/trainingonlocation',
          },
          // {
          //   title: 'Dikonfirmasi & Pending',
          //   href: '/jadwaltraining',
          // },
          {
            title: 'Dibatalkan',
            href: '/jadwaltraining/dibatalkan',
          },
          {
            title: 'Selesai',
            href: '/jadwaltraining/selesai',
          }
        ]
      },
      {
        title: 'Website',
        icon: LanguageOutlinedIcon,
        children: [
          {
            title: 'SEO Home',
            href: '/seo',
          },
          {
            title: 'SEO Blog',
            href: '/blog/post',
          },
          {
            title: 'Set Isi Website',
            href: '/isiweb',
          },
          {
            title: 'Spesifikasi Langganan',
            href: '/spesifikasilangganan',
          },
          {
            title: 'Info Popup',
            href: '/'
          }
        ]
      },
      {
        title: 'Blog',
        href: '/blog',
        icon: ReceiptIcon,
        children: [
          {
            title: 'Kategori',
            href: '/blog/kategori',
          },
          {
            title: 'Sub Kategori',
            href: '/blog/subkategori',
          },
          {
            title: 'Post',
            href: '/blog/post',
          },
          {
            title: 'Review Post',
            href: '/blog/review',
          }
        ]
      },
      {
        title: 'Produk',
        href: '/produk',
        icon: CategoryOutlinedIcon,
        children: [
          {
            title: 'Kategori',
            href: '/produk/kategori',
          },
          {
            title: 'Varian',
            href: '/produk/varian',
          },
          {
            title: 'SKU Produk',
            href: '/produk/sku',
          },
          {
            title: 'Produk Review',
            href: '/produk/review'
          }
        ]
      },
      {
        title: 'Partner',
        href: '/partner',
        icon: GroupWorkOutlinedIcon,
        children: [
          {
            title: 'Data Partner',
            href: '/partner'
          },
          {
            title: 'Saldo Partner',
            href: '/partner'
          }
        ]
      },
      // {
      //   title: 'Sales',
      //   href: '/sales',
      //   icon: Localmall,
      //   children: [
      //     {
      //       title: 'Pendaftaran',
      //       href: '/sales/pendaftaran',
      //     },
      //     {
      //       title: 'Data Sales',
      //       href: '/sales',
      //     },
      //     {
      //       title: 'Suspend',
      //       href: '/sales/suspend',
      //     },
      //     {
      //       title: 'Ditolak',
      //       href: '/sales/reject',
      //     },
      //     {
      //       title: 'Progress',
      //       href: '/sales/progress',
      //     },
      //     {
      //       title: 'Saldo Sales',
      //       href: '/sales/saldo',
      //     },
      //   ]
      // },
      // {
      //   title: 'JOB',
      //   href: '/sales/job',
      //   icon: WorkOutlineOutlinedIcon,
      //   children: [
      //     {
      //       title: 'Send Job Trainer',
      //       href: '/sales/job'
      //     },
      //     {
      //       title: 'Verif Job Trainer',
      //       href: '/sales/job/verif'
      //     },
      //     {
      //       title: 'Riwayat Verifikasi JOB',
      //       href: '/sales/job/verif/riwayat'
      //     }
      //   ]
      // },
      {
        title: 'Support',
        href: '/support',
        icon: HelpIcon,
        children: [
          {
            title: 'F. A. Q',
            href: '/support/faq'
          },
          {
            title: 'Help Center',
            href: '/support/help'
          },
          {
            title: 'Help Kategori',
            href: '/support/help_kategori'
          },
          {
            title: 'Vidio Kategori',
            href: '/support/vidio_kategori'
          },
          {
            title: 'Privacy Police',
            href: '/support/privacy'
          },
          {
            title: 'Term of Condition',
            href: '/support/toc'
          },
          {
            title: 'S&K Ref Sales',
            href: '/support/snkref'
          },
          {
            title: 'S&K Ref Outlet',
            href: '/support/snkrefoutlet'
          },
          {
            title: 'S&K Custom Aplikasi',
            href: '/support/snkcustom'
          },
        ]
      },
      {
        title: 'Feedback',
        href: '/feedback',
        icon: ListAltIcon,
        children: [
          {
            title: 'Masukkan Pengguna',
            href: '/feedback/masukkan',
          },
          {
            title: 'Testimoni Pengguna',
            href: '/feedback/testimoni',
          }, 
          {
            title: 'Vidio',
            href:'/feedback/vidio'
          },
          {
            title: 'Laporan Bug',
            href: '/feedback/bug',
          }
        ]
      },
      {
        title: 'Setting',
        href: '/setting',
        icon: SettingsIcon,
        // children: [
        //   {
        //     title: 'Konfigurasi',
        //     href: '/config',
        //   },
        //   {
        //     title: 'User',
        //     href: '/user',
        //   },
        // ]
      },
    ]
  },
]

const navigation = [
  {
    title: 'Menu',
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: DashboardIcon
      }, 
      {
        title: 'All Summary',
        href: '/ringkasan',
        icon: Timeline
      },
      {
        title: 'Outlet',
        href: '/outlet',
        icon: StoreIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/outlet/ringkasan-outlet',
          },
          {
            title: 'Data Outlet',
            href: '/outlet/daftar-outlet',
          },
          {
            title: 'Max Antrian',
            href: '/outlet/antrian-outlet',
          },
          {
            title: 'Outlet Aktif',
            href: '/outlet/outlet-aktif',
          },
          {
            title: 'Laporan',
            href: '/outlet/laporan'
          },
          {
            title: 'Bonus Masa Aktif',
            href: '/outlet/bonus',
          },
          {
            title: 'Data Pemilik',
            href: '/outlet/owner',
          },
          {
            title: 'Outlet Archive',
            href: '/outlet/outlet-archive',
          },
          {
            title: 'Upgrade / Downgrade',
            href: '/outlet/outlet-upgrade',
          },
          {
            title: 'Trial Bisnis',
            href: '/outlet/trialbisnis'
          },
          { 
            title: 'Perpanjangan Masa Trial',
            href: '/outlet/extendtrial'
          },
           { 
            title: 'WA Bisnis',
            href: '/outlet/wa_bisnis'
          }
          // {
          //   title: 'Beta Tester',
          //   href: '/outlet/betatester'
          // }
        ]
      },
      {
        title: 'Beta Tester',
        // href: '/outlet',
        icon: EmojiNatureIcon,
        children: [
          {
            title: 'Pendaftaran',
            href: '/betatester/pendaftaran',
          },
          {
            title: 'Data Betatester',
            href: '/betatester/data',
          },
          {
            title: 'Laporan Bug',
            href: '/betatester/bug',
          },
          {
            title: 'Report',
            href: '/betatester/report',
          },
         
        ]
      },
      {
        title: 'Order',
        icon: AssignmentReturnedIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/ringkasan_order',
          },
          {
            title: 'Data Order',
            href: '/daftar_order',
          },
          {
            title: 'Laporan',
            href: '/laporan_order',
          },
          {
            title: 'Repeat Order',
            href: '/repeat_order',
          },
        ]
      },
      {
        title: 'Poin',
        icon: AdjustOutlinedIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/poin',
          },
          {
            title: 'Poin Outlet',
            href: '/poin/outlet',
          },
          {
            title: 'Riwayat',
            href: '/poin/riwayat',
          },
          {
            title: 'Pengaturan',
            href: '/poin/pengaturan',
          }
        ]
      },
      {
        title: 'Promosi',
        icon: LocalOffer,
        children: [
          {
            title: 'Ringkasan',
            href: '/promo',
          },
          {
            title: 'Data Promo',
            href: '/promo/data',
          },
        ]
      },
      {
        title: 'Referal',
        href: '/referal',
        icon: ReferalIcon,
        children: [
          {
            title: 'Data Referal',
            href: '/referal/data'
          },
          {
            title: 'List Pemakaian',
            href: '/referal/pemakaian'
          },
          {
            title: 'Pengaturan',
            href: '/referal/pengaturan'
          },
        ]
      },
      // {
      //   title: 'Prospek Pelanggan',
      //   href: '/prospek',
      //   icon: GroupAddIcon,
      //   children: [
      //     {
      //       title: 'Ringkasan',
      //       href: '/prospek/ringkasan'
      //     },
      //     {
      //       title: 'Data Batch',
      //       href: '/prospek/batch',
      //     },
      //     {
      //       title: 'Data Prospek',
      //       href: '/prospek',
      //     },
      //     {
      //       title: 'Prospek Sukses',
      //       href: '/prospek/sukses'
      //     },
      //     {
      //       title: 'Performa',
      //       href: '/prospek/performa'
      //     }
      //   ]
      // },
      {
        title: 'Customer Journey',
        href: '/prospek_new',
        icon: GroupAddIcon,
        children: [
          // {
          //   title: 'Ringkasan',
          //   href: '/prospek_new/ringkasan'
          // },
          {
            title: 'Data Prospek',
            href: '/prospek_new',
          },
          {
            title: 'Klaim Prospek',
            href: '/prospek_new/klaim'
          },
          {
            title: 'Performa CS',
            href: '/prospek_new/performa_cs'
          },
          {
            title: 'Performa Sales',
            href: '/prospek_new/performa_sales'
          }
        ]
      },
     
      {
        title: 'Mitra',
        href: '/voucher',
        icon: GroupWorkOutlinedIcon,
        children: [
          // {
          //   title: 'Pendaftaran',
          //   href: '/partner/pendaftaran'
          // },
          {
            title: 'Data Promoter',
            href: '/promoter'
          },
          {
            title: 'Data Reseller',
            href: '/reseller' 
          },
          // {
          //   title: 'Data Partner',
          //   href: '/partner'
          // },
          {
            title: 'Suspend',
            href: '/partner/suspend',
          },
          // {
          //   title: 'Ditolak',
          //   href: '/partner/reject',
          // },
          {
            title: 'Mitra Level',
            href: '/mitralevel', 
          },
          // {
          //   title: 'Saldo Partner',
          //   href: '/partner/saldo'
          // },
          // {
          //   title: 'Withdraw Partner',
          //   href: '/partner/withdraw'
          // }
        ]
      },
      {
        title: 'Voucher',
        href: '/voucher',
        icon: VoucherIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/voucher/ringkasan'
          },
          {
            title: 'Pembuatan',
            href: '/voucher/pembuatan'
          },
          // {
          //   title: 'Request',
          //   href: '/voucher/request'
          // },
          {
            title: 'Approve Cashback',
            href: '/voucher/approval'
          },
          {
            title: 'Data Produk',
            href: '/voucher/produk'
          },
          {
            title: 'Data Voucher',
            href: '/voucher/data'
          },
          {
            title: 'Pembelian Reseller',
            href: '/voucher/data/rsl'
          },
          {
            title: 'List Pemakaian',
            href: '/voucher/pemakaian'
          },
          {
            title: 'Laporan Pemakaian',
            href: '/voucher/laporan'
          },
          {
            title: 'Laporan Pendapatan',
            href: '/voucher/laporan/pendapatan'
          },
        ]
      },
      // {
      //   title: 'QRIS',
      //   icon: DeveloperModeIcon,
      //   children: [
      //     {
      //       title: 'Aktivasi',
      //       href: '/qris/aktivasi',
      //     },
      //     {
      //       title: 'Riwayat',
      //       href: '/qris/histori',
      //     },
      //   ]
      // },
      // {
      //   title: 'Misi & Tantangan',
      //   href: '/mission',
      //   icon: CardGiftcardOutlinedIcon,
      //   children: [
      //     {
      //       title: 'Buat Baru',
      //       href: '/mission/data'
      //     },
      //     {
      //       title: 'Top Participant',
      //       href: '/mission/participant'
      //     },
      //     {
      //       title: 'Validasi Poin',
      //       href: '/mission/validasi'
      //     },
      //   ]
      // },
      {
        title: 'Request Aplikasi',
        href: '/outletaktivasi',
        icon: PhoneIphoneIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/outletaktivasi/ringkasan'
          },
          {
            title: 'Draf',
            href: '/outletaktivasi/draf'
          },
          {
            title: 'Proses',
            href: '/outletaktivasi',
          },
          {
            title: 'Publish',
            href: '/outletaktivasi/publish'
          },
          {
            title: 'Pembayaran',
            href: '/outletaktivasi/pembayaran'
          },
          {
            title: 'Upgrade',
            href: '/outletaktivasi/upgrade'
          },
          {
            title: 'Laporan',
            href: '/outletaktivasi/laporan'
          },
          // {
          //   title: 'Request Custom',
          //   href: '/outletaktivasi/custom'
          // },

        ]
      },
      {
        title: 'Jadwal Training',
        href: '/jadwaltraining',
        icon: CameraFrontIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/jadwaltraining/ringkasan',
          },
          {
            title: 'Training Virtual',
            href: '/jadwaltraining/trainingvirtual',
          },
          {
            title: 'Training On Location',
            href: '/jadwaltraining/trainingonlocation',
          },
          // {
          //   title: 'Dikonfirmasi & Pending',
          //   href: '/jadwaltraining',
          // },
          {
            title: 'Selesai',
            href: '/jadwaltraining/selesai',
          },
          {
            title: 'Dibatalkan',
            href: '/jadwaltraining/dibatalkan',
          }
        ]
      },
      {
        title: 'Sales',
        href: '/sales_v2',
        icon: DirectionsRun,
        children: [
          {
            title: 'Data Sales',
            href: '/sales_v2',
          },
          {
            title: 'Performa Sales',
            href: '/sales_v2/performa',
          },
          {
            title: 'Sales Nonaktif',
            href: '/sales_v2/nonaktif',
          },
          {
            title: 'Riwayat',
            href: '/sales_v2/riwayat',
          }
        ]
      },
      {
        title: 'Penjualan',
        href: '/penjualan',
        icon: ShoppingCartIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/penjualan/ringkasan',
          },
          {
            title: 'Buat Invoice',
            href: '/penjualan/invoice',
          },
          {
            title: 'Daftar Product',
            href: '/penjualan/produk/retail',
          },
          {
            title: 'Data Order',
            href: '/penjualan/data_order',
          },
          {
            title: 'Pre-Order Mitra',
            href: '/penjualan/preorder',
          },
          // {
          //   title: 'Atur Ongkir',
          //   href: '/penjualan/ongkir',
          // },
          // {
          //   title: 'Produk Retail',
          //   href: '/penjualan/produk/retail',
          // },
          // {
          //   title: 'Pending Order',
          //   href: '/penjualan/pendingorder'
          // },
          // {
          //   title: 'Konfirmasi Order',
          //   href: '/penjualan/konfirmasiorder'
          // },
          // {
          //   title: 'In Proses',
          //   href: '/penjualan/inproses'
          // },
          // {
          //   title: 'Finished Order',
          //   href: '/penjualan/selesai'
          // },
          {
            title: 'Product in Cart',
            href: '/penjualan/onchart'
          },
          {
            title: 'Top Product View',
            href: '/penjualan/topproductview'
          }
        ]
      },
      {
        title: 'Withdrawal',
        href: '/withdrawal',
        icon: MonetizationOnIcon,
        children: [
          // {
          //   title: 'Ringkasan',
          //   href: '/prospek_new/ringkasan'
          // },
          {
            title: 'WD Pending',
            href: '/withdrawal',
          },
          {
            title: 'WD Proses',
            href: '/withdrawal/proses',
          },
          {
            title: 'WD Sukses',
            href: '/withdrawal/sukses'
          },
          {
            title: 'WD Reject',
            href: '/withdrawal/batal'
          }
        ]
      },
      {
        title: 'Blaztap Order',
        href: '/manufacture',
        icon: PostAddIcon,
        children: [
          {
            title: 'Order Blaztap',
            href: '/blaztap/order_blaztap'
          },
          {
            title: 'Barang Keluar',
            href: '/blaztap/barang_keluar'
          },
          {
            title: 'Laporan Penjualan',
            href: '/blaztap/laporan'
          }
        ]
      },
      {
        title: 'Blaztap MFG',
        href: '/manufacture',
        icon: BuildIcon,
        children: [
          {
            title: 'Bahan Baku',
            href: '/blaztap/bahan_baku'
          },
          {
            title: 'Daftar BOM',
            href: '/blaztap/bom',
          },
          {
            title: 'Pembelian Bahan Baku',
            href: '/blaztap/pembelian_bahan',
          },
          {
            title: 'Manufacture',
            href: '/blaztap/manufacture'
          },
          {
            title: 'Serial Number',
            href: '/blaztap/serial_number'
          },
          {
            title: 'Stock Product',
            href: '/blaztap/stock_product'
          }
        ]
      },
      {
        title: 'Event',
        href: '/event',
        icon: EventIcon
      },
       
    ]
  }, 
  {
    title: 'Management',
    pages: [
      {
        title: 'DropBox',
        href: '/dropbox',
        icon: AllInboxIcon
      },
      {
        title: 'Survey',
        href: '/survey',
        icon: HowToVoteIcon,
        children: [
          {
            title: 'Buat Soal',
            href: '/survey'
          },
          {
            title:'report',
            href:'/survey/report'
          }
        ]
      },
      // {
      //   title: 'Dropbox',
      //   href: '/dropbox',
      //   icon: MailIcon,
      // },
       {
        title: 'Riset',
        // href: '/riset',
        icon: EmojiObjectsIcon,
        children: [
          {
            title: 'Referensi Harga',
            href: '/referensiharga'
          },
          {
            title: 'Nota Web',
            href: '/nota'
          },
          {
            title: 'Transaksi per Outlet',
            href: '/transaksiratarata'
          },
         
        ]
      },
      {
        title: 'Shortlink',
        href: '/shortlink',
        icon: LinkIcon,
        children: [
          {
            title: 'Ringkasan',
            href: '/shortlink/ringkasan'
          },
          {
            title: 'Data',
            href: '/shortlink/data'
          }
        ]


      },
      
      {
        title: 'Banner',
        href: '/banner',
        icon: ViewCarouselOutlinedIcon,
        children: [
          {
            title: 'Banner App',
            href: '/banner',
          },
          {
            title: 'Banner Promosi',
            href: '/banner/promosi',
          }
        ]
      },
      {
        title: 'Broadcast',
        href: '/broadcast',
        icon: MailIcon,
      },
      {
        title: 'Auto Broadcast',
        href: '/autobroadcast',
        icon: AutorenewIcon,
      },
      {
        title: 'Gallery',
        href: '/images',
        icon: ImageIcon,
      },
      {
        title: 'Website',
        href: '/website',
        icon: LanguageOutlinedIcon,
        children: [
          {
            title: 'SEO Home',
            href: '/seo',
          },
          {
            title: 'SEO Blog',
            href: '/blog/post',
          },
          {
            title: 'Set Isi Website',
            href: '/isiweb',
          },
          {
            title: 'Spesifikasi Langganan',
            href: '/spesifikasilangganan',
          },
          {
            title: 'Promo Special',
            href: '/promospecial'
          },
          {
            title: 'Info Popup',
            href: '/'
          }
        ]
      },
      {
        title: 'Blog',
        href: '/blog',
        icon: ReceiptIcon,
        children: [
          {
            title: 'Kategori',
            href: '/blog/kategori',
          },
          {
            title: 'Sub Kategori',
            href: '/blog/subkategori',
          },
          {
            title: 'Post',
            href: '/blog/post',
          },
          {
            title: 'Review Post',
            href: '/blog/review',
          }
        ]
      },
      {
        title: 'Produk',
        href: '/produk',
        icon: CategoryOutlinedIcon,
        children: [
          {
            title: 'Kategori',
            href: '/produk/kategori',
          },
          {
            title: 'Varian',
            href: '/produk/varian',
          },
          {
            title: 'SKU Produk',
            href: '/produk/sku',
          },
          {
            title: 'Produk Review',
            href: '/produk/review'
          }
        ]
      },
      
      // {
      //   title: 'Partner',
      //   href: '/partner',
      //   icon: GroupWorkOutlinedIcon,
      //   children: [
      //     {
      //       title: 'Pendaftaran',
      //       href: '/partner/pendaftaran'
      //     },
      //     {
      //       title: 'Data Partner',
      //       href: '/partner'
      //     },
      //     {
      //       title: 'Suspend',
      //       href: '/partner/suspend',
      //     },
      //     {
      //       title: 'Ditolak',
      //       href: '/partner/reject',
      //     },
      //     {
      //       title: 'Saldo Partner',
      //       href: '/partner/saldo'
      //     },
      //     {
      //       title: 'Withdraw Partner',
      //       href: '/partner/withdraw'
      //     }
      //   ]
      // },
      // {
      //   title: 'JOB',
      //   href: '/sales/job',
      //   icon: WorkOutlineOutlinedIcon,
      //   children: [
      //     {
      //       title: 'Send Job Trainer',
      //       href: '/sales/job'
      //     },
      //     {
      //       title: 'Verif Job Trainer',
      //       href: '/sales/job/verif'
      //     },
      //     {
      //       title: 'Riwayat Verifikasi JOB',
      //       href: '/sales/job/verif/riwayat'
      //     }
      //   ]
      // },
      {
        title: 'Support',
        href: '/support',
        icon: HelpIcon,
        children: [
          {
            title: 'F. A. Q',
            href: '/support/faq'
          },
          {
            title: 'Help Center',
            href: '/support/help'
          },
          {
            title: 'Help Kategori',
            href: '/support/help_kategori'
          },
          {
            title: 'Vidio Kategori',
            href: '/support/vidio_kategori'
          },
          {
            title: 'Privacy Police',
            href: '/support/privacy'
          },
          {
            title: 'Term of Condition',
            href: '/support/toc'
          },
          {
            title: 'S&K Ref Sales',
            href: '/support/snkref'
          },
          {
            title: 'S&K Ref Outlet',
            href: '/support/snkrefoutlet'
          },
          {
            title: 'S&K Custom Aplikasi',
            href: '/support/snkcustom'
          },
          {
            title: 'S&K Free Upgrade',
            href: '/support/snkfreeupgrade'
          },
          {
            title: 'S&K Beta Pendaftaran',
            href: '/support/snkbetapendaftaran'
          },
          {
            title: 'S&K Beta Instruksi',
            href: '/support/snkbetainstruksi'
          },
          {
            title: 'S&K Mitra Reseller',
            href: '/support/snkmitrareseller'
          },
        ]
      },
      {
        title: 'Feedback',
        href: '/feedback',
        icon: ListAltIcon,
        children: [
          {
            title: 'Masukkan Pengguna',
            href: '/feedback/masukkan',
          },
          {
            title: 'Testimoni Pengguna',
            href: '/feedback/testimoni',
          },
          {
            title: 'Vidio',
            href:'/feedback/vidio'
          },
          {
            title: 'Laporan Bug',
            href: '/feedback/bug',
          },
          // {
          //   title: 'Laporan Bug Test',
          //   href: '/feedback/bugtest',
          // }
        ]
      },
      {
        title: 'Setting',
        href: '/setting',
        icon: SettingsIcon,
        // children: [
        //   {
        //     title: 'Konfigurasi',
        //     href: '/config',
        //   },
        //   {
        //     title: 'User',
        //     href: '/user',
        //   },
        // ]
      },
    ]
  },
  
];

export default navigation;
