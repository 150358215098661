import * as firebase from 'firebase';

import 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyCNwBLW103OUF1scYtSjXDl1yrTmW3Zymo",
    authDomain: "bilassystem-2a77f.firebaseapp.com",
    databaseURL: "https://bilassystem-2a77f.firebaseio.com",
    projectId: "bilassystem-2a77f",
    storageBucket: "bilassystem-2a77f.appspot.com",
    messagingSenderId: "117066416094",
    appId: "1:117066416094:web:226dc25a4cbc945c0a674f",
    measurementId: "G-EF82CBHSGV"
};
firebase.initializeApp(firebaseConfig);


export const db = firebase.firestore();
// const settings = {timestampsInSnapshots: true};
// db.settings(settings);

export var msg = firebase.messaging();
export const REACT_APP_VAPID_KEY = "BHaMsh4_SFx9cQ24cZYZzyBnE0AXkEWT7IbRb0Cephbm0ULG4lrgOpHwddplqvaIaX6WptjFnzeZrPzGGnArcGk"

export var tokenmess = ""

// firebase.messaging().onTokenRefresh(function() {
//   firebase.messaging().getToken({vapidKey:REACT_APP_VAPID_KEY})
//   .then(function(refreshedToken) {
//     console.log('Token refreshed.');
//     tokenmess = refreshedToken
//     // Indicate that the new Instance ID token has not yet been sent to the
//     // app server.
//     // setTokenSentToServer(false);
//     // // Send Instance ID token to app server.
//     // sendTokenToServer(refreshedToken);
//     // ...
//   })
//   .catch(function(err) {
//     console.log('Unable to retrieve refreshed token ', err);
//     // showToken('Unable to retrieve refreshed token ', err);
//   });
// });


firebase.messaging().requestPermission().then(function() {
    // firebase.messaging().getToken({vapidKey:REACT_APP_VAPID_KEY}).then((currentToken) => {

    //   if(currentToken){
    //     console.log(currentToken)
    //     tokenmess = currentToken
    //   }else{
    //     console.log("data tidak dapat")
    //   }
    // }).catch((err)=>{
    //   console.log("error")
    // });


    // firebase.messaging().onTokenRefresh(function() {
      // firebase.messaging().onTokenRefresh()
      
      firebase.messaging().getToken({vapidKey:REACT_APP_VAPID_KEY}).then((currentToken) => {
       
        if(currentToken){
          console.log(currentToken)
          tokenmess = currentToken

        }else{
          console.log("data tidak dapat")
        }
      }).catch((err)=>{
        console.log("error")
      })
    // })
    })

// export var topic = ""

// firebase.messaging().requestPermission().then(function() {
//   msg.messaging().subscribeToTopic('topike').then((ad) => {
//     if(ad){
//       console.log(ad)
//       topic = ad
//     }else{
//       console.log("data tidak dapat")
//     }
//   }).catch((err)=>{
//     console.log("error")
//   });
//   })




// const messaging = firebase.messaging();
// messaging.requestPermission().then(function() {
//   console.log('permission granted.');
//   return messaging.getToken();
// })
// .then(function(token) {
//   console.log("ceks: "+token);
// })
// .catch(function(err) {
//   console.log('Unable to get permission to notify.', err);
// });
// firebase.messaging().onMessage(function(payload) {
//   console.log('Message received. ', payload);
// });

export default firebase;