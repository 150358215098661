import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import GlobalStyles from 'constants/style';

const YNButton = props => {

    const classes = GlobalStyles()
    const { onYesButton, onNoButton, noText, yesText, formName, ...rest } = props;

    return (
        <div className={classes.flexContainerRight} {...rest}>
            {/* <Button className={classes.margin_l_10} color="secondary" variant="contained" onClick={onNoButton}>
                {noText ? noText : 'No'}
            </Button>
            <Button className={classes.margin_l_10} color="primary" variant="contained" onClick={onYesButton}>
                {yesText ? yesText : 'Yes'}
            </Button> */}
            <button className={classes.buttonSecondary} onClick={onNoButton}>
                {noText ? noText : 'No'}
            </button>
            <button type="submit" form={formName} className={classes.buttonPrimary} onClick={onYesButton}>
                {yesText ? yesText : 'Yes'}
            </button>
        </div>
    );
};

YNButton.propTypes = {
    onNoButton: PropTypes.func,
    onYesButton: PropTypes.func,
    yesText: PropTypes.string,
    noText: PropTypes.string,
    formName: PropTypes.string
};

export default YNButton;
