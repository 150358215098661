const Reducer = (state, action) => {
    switch (action.type) {
        case 'POST':
            return {
                ...state,
                post: {
                    id: action.content.id,
                    data: action.content.data,
                    editmode: action.content.editmode,
                },
            };
        case 'LOGIN':
            return {
                ...state,
                login: {
                    id: action.content.id,
                    email: action.content.email,
                    nama: action.content.nama,
                    chatname: action.content.chatname,
                    akses: action.content.akses,
                    hak_akses: action.content.hak_akses,
                    username: action.content.username,
                    data: action.content.data
                },
            };
        case 'NOTIF':
            return {
                ...state,
                notif: action.content,
            };
            case 'SUBDATA':
                return {
                    ...state,
                    subdata: action.content,
                };
        case 'OUTLET':
            return {
                ...state,
                outlet: action.content,
            };
        case 'OWNER':
            return {
                ...state,
                owner: action.content,
            };
        case 'STAFF':
            return {
                ...state,
                staff: action.content,
            }; 
        case 'TANGGAL':
            return {
                ...state,
                tgl: action.content,
            }; 
        case 'TRANSAKSI':
            return {
                ...state,
                transaksi: action.content,
            }; 
        case 'REVIEW':
            return {
                ...state,
                review: action.content,
            };
        case 'LAYANAN':
            return {
                ...state,
                layanan: action.content,
            };
        case 'PAGE':
            return {
                ...state,
                page: {
                data: action.content, 
                filter_tipe : action.filter_tipe
                }
            };
            case 'JADWAL':
            return {
                ...state,
                jadwal: action.content,
            };
            case 'REQUEST':
            return {
                ...state,
                request: action.content,
            };
            case 'THEME':
            return {
                ...state,
                theme: action.content,
            };

        default:
            return state;
    }
};

export default Reducer;