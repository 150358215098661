import React from 'react'
import { Route } from 'react-router';
import { Link as RouterLink } from "react-router-dom";
import { Breadcrumbs, Typography } from "@material-ui/core";


export default function SimpleBreadcrumbs(props) {
    return (
        <Route>
            {({ location }) => {
                const pathnames = location.pathname.split("/").filter(x => x);
                return (
                    <Breadcrumbs aria-label="Breadcrumb">
                        <RouterLink to="/dashboard">
                            <Typography variant="overline" color="textPrimary">
                                HOME
                            </Typography>
                        </RouterLink>
                        {pathnames.map((value, index) => {
                            const last = index === pathnames.length - 1;
                            const to = `/${pathnames.slice(0, index + 1).join("/")}`;

                            return last ? (
                                <Typography variant="overline" color="textPrimary" key={to}>
                                    {value.toUpperCase()}
                                </Typography>
                            ) : (
                                    <RouterLink to={to} key={to}>
                                        <Typography variant="overline" color="textPrimary" key={to}>
                                            {value.toUpperCase()}
                                        </Typography>
                                    </RouterLink>
                                );
                        })}
                    </Breadcrumbs>
                );
            }}
        </Route>
    );
}