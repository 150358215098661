import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Toolbar,
  Input,
  IconButton,
  Tooltip,
  Divider,
  List
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useRouter from 'utils/useRouter';
import ChatListItem from 'components/ChatListItem';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white
  },
  searchInput: {
    flexGrow: 1
  }
}));

const ConversationList = props => {
  const { conversations, onChatClick, className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();
  const selectedConversation = router.match.params.room;
  const [filter, setFilter] = React.useState([])

  const handleSearch = (event) => {
    setFilter(conversations.filter(dt => {
      return (
        dt.email.toLowerCase().search(event.target.value.toLowerCase()) !== -1 ||
        dt.nama.toLowerCase().search(event.target.value.toLowerCase()) !== -1
      );
    }));
  };

  React.useEffect(() => {
    setFilter(conversations)
  }, [conversations])

  return (
    <div {...rest} className={clsx(classes.root, className)}>

      <Toolbar>
        <Input onChange={handleSearch} className={classes.searchInput} disableUnderline placeholder="Search conversations"/>
        <Tooltip title="Search">
          <IconButton edge="end">
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>

      <Divider />

      <PerfectScrollbar>
      <List disablePadding>
        {filter.map((conversation, i) => (
          <ChatListItem
            active={conversation.nama === selectedConversation}
            conversation={conversation}
            divider={i < filter.length - 1}
            key={conversation.id}
            onItemClick={()=>onChatClick(conversation.nama)}
          />
        ))}
      </List>
      </PerfectScrollbar>

    </div>
  );
};

ConversationList.propTypes = {
  className: PropTypes.string,
  onChatClick: PropTypes.func,
  conversations: PropTypes.array.isRequired
};

export default ConversationList;
