import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, colors, Divider, } from '@material-ui/core';
import { Label } from 'components';
import GlobalStyles from 'constants/style';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 120,
    marginLeft: '5px',
    marginRight: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  content: {
    padding: '5px',
  },
}));

function CardBlock(props) {
  const { data, onHandleClick, usePrev } = props
  const classes = useStyles();
  const globalClass = GlobalStyles();
  const def = {
    title: '',
    value: 0,
    prev_value: 0,
    variant: 'info',
    color: 'info',
    primary: 'success',
    secondary: 'disable',
  };

  const variantColor = {
    success: colors.green[600],
    error: colors.red[600],
    warning: colors.amber[700],
    info: colors.blue[700],
    disable: colors.grey[500]
  }

  let value = data != null ? data : def

  return (
    <Card className={classes.root} onClick={onHandleClick}>
      <div className={classes.content}>
        <Typography style={{ color: variantColor[value.color] }} variant="overline">{value.title}</Typography>
        <Divider />
        <div className={clsx(globalClass.flexContainer, globalClass.margin_t_10)}>
          <Label className={globalClass.dotSmall} color={variantColor[value.primary]} shape="rounded" />
          <Typography className={globalClass.margin_l_10} variant="h6">{value.value}</Typography>

          {usePrev &&
            <>
              <Label className={clsx(globalClass.margin_l_10, globalClass.dotSmall)} color={variantColor[value.secondary]} shape="rounded" />
              <Typography className={globalClass.margin_l_10} variant="h6">{value.prev_value}</Typography>
            </>
          }
        </div>
      </div>
    </Card>
  );
}
export default CardBlock;

CardBlock.propTypes = {
  data: PropTypes.object,
  onHandleClick: PropTypes.func,
  usePrev: PropTypes.bool
};

CardBlock.defaultProps = {
  usePrev: false
};