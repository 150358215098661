import React, { Fragment } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Divider, Toolbar, Tooltip, IconButton, Typography } from '@material-ui/core';
import { ChatInput, ChatBubble, StatusBullet } from 'components';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.white
  },
  backButton: {
    marginRight: theme.spacing(2),
    '@media (min-width: 864px)': {
      display: 'none'
    }
  },
  user: {
    flexShrink: 0,
    flexGrow: 1
  },
  activity: {
    display: 'flex',
    alignItems: 'center'
  },
  statusBullet: {
    marginRight: theme.spacing(1)
  },
  detail: {
    flexGrow: 1,
    overflow: 'hidden',
    maxHeight: '100%'
  },
  inner: {
    padding: theme.spacing(2)
  }
}));

const ConversationDetails = props => {

  const { conversation, className, ...rest } = props;
  const messagesEndRef = React.useRef(null)
  const classes = useStyles();

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  React.useEffect(scrollToBottom);

  return (
    <div {...rest} className={clsx(classes.root, className)}>

      <Toolbar>
        <Tooltip title="Back">
          <IconButton className={classes.backButton} component={RouterLink} edge="start" to="/livechat">
            <KeyboardBackspaceIcon />
          </IconButton>
        </Tooltip>

        <div className={classes.user}>
          <Typography variant="h6">{conversation.nama}</Typography>
          <div className={classes.activity}>
            <Fragment>
              <StatusBullet
                className={classes.statusBullet}
                color={conversation.active ? "success" : "error"}
                size="small"
              />
              <Typography variant="body2">{conversation.active ? "Active" : "Closed"}</Typography>
            </Fragment>
          </div>
        </div>
      </Toolbar>

      <Divider />

      <div className={classes.detail}  ref={messagesEndRef}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            {conversation.messages.map(message => {
              return (
                <ChatBubble
                  key={message.id}
                  message={message} //
                />
              );
            })}
          </div>
        </PerfectScrollbar>
      </div>

      <Divider />

      <ChatInput />
    </div>
  );
};

ConversationDetails.propTypes = {
  className: PropTypes.string,
  conversation: PropTypes.object.isRequired
};

export default ConversationDetails;
