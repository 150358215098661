import React, {createContext, useReducer} from "react";
import Reducer from './reducer'
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../theme';
import dark from '../theme/dark';
// eslint-disable-next-line no-use-before-define

const initialState = {
    post: {
        id: null,
        data: null,
        editmode: false,
    },
    login: {
        id: null,
        email: null,
        nama: null,
        chatname: null,
        akses: null,
        hak_akses: null,
        username: null,
        data: null
    },
    notif: [],
    subdata: [],
    outlet: [],
    owner: [],
    staff: [],
    tgl: [],
    transaksi: [],
    review: [],
    layanan: [],
    tanggal: [],
    page:{
        data:[],
        filter_tipe: false
    },
    jadwal:[],
    request:[],
    theme:[]
};

const Store = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    var tema = localStorage.getItem('tema')
        if(tema != null){
            if(tema === 'false') {
                state.theme = false;
            }else{
                state.theme = true;
            }
        }
        else{
            state.theme = false;
        }

    return (
         <ThemeProvider theme={state.theme === false ? theme : dark}>
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
        </ThemeProvider>
    )
};

export const Context = createContext(initialState);
export default Store;