import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, } from '@material-ui/core';
import { AvatarIcon } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    width: 'auto',
    marginLeft: '5px',
    marginRight: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: '10px',
  },
}));

function CardBlock(props) {
  const { data, onHandleClick } = props
  const classes = useStyles();
  const def = {
    title: '',
    value: 0,
    prev_value: 0,
    variant: 'info',
    color: 'info'
  };

  let value = data != null ? data : def

  return (
    <Card className={classes.root} onClick={onHandleClick}>
      <div className={classes.container}>
        <AvatarIcon size={36} color={value.color} variant={value.variant} />
        <div className={classes.content}>
          <Typography variant="h6">{value.title}</Typography>
          <Typography variant="h5">{value.value}</Typography>
        </div>
      </div>
    </Card>
  );
}
export default CardBlock;

CardBlock.propTypes = {
  data: PropTypes.object,
  onHandleClick: PropTypes.func,
};