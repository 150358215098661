import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const basic = '#FFFFFF';
const strip = '#FAFAFA';
const navbar = '#1669BB';

export default {
  black,
  white,
  basic,
  strip,
  navbar,
  primary: {
    contrastText: white,
    dark: '#1669BB',
    main: '#1976D2',
    light: colors.indigo[100]
  },
  secondary: {
    contrastText: white,
    dark: '#FF8F00',
    main: '#FF9800',
    light: '#FFE082'
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  link: colors.blue[800],
  icon: colors.blueGrey[400],
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: colors.grey[200]
};
