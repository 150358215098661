import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { amber, green, blue, red, grey } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Clear';
import InfoIcon from '@material-ui/icons/Info';
import BlockIcon from '@material-ui/icons/Block';
import WarningIcon from '@material-ui/icons/PriorityHigh';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleIcon from '@material-ui/icons/People';
import StoreIcon from '@material-ui/icons/Storefront';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ExpenseIcon from '@material-ui/icons/ZoomOutMap';
import PortraitIcon from '@material-ui/icons/Portrait';
import { makeStyles } from '@material-ui/core/styles';
import gradients from 'utils/gradients';
// import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';

const variantIcon = {
  success: CheckIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  finance: AttachMoneyIcon,
  people: PeopleIcon,
  store: StoreIcon,
  wallet: WalletIcon,
  expense: ExpenseIcon,
  person: PortraitIcon,
  disable: BlockIcon,
  cek: CheckIcon,
  close: CloseIcon,
  link: LinkIcon
};

const variantColor = {
  success: green[600],
  error: red[600],
  warning: amber[700],
  info: blue[700],
  disable: grey
}

const variantGradient = {
  success: gradients.green,
  error: gradients.red,
  warning: gradients.orange,
  info: gradients.blue,
}

const useStyles1 = makeStyles(() => ({
  icon: {
    fontSize: 28,
    color:'white'
  },
  iconVariant: {
    opacity: 0.9,
  },
}));

export default function AvatarIcon(props) {
  const classes = useStyles1();
  const { gradient, color, variant, size } = props;
  const Icon = variantIcon[variant];
  const Warna = variantColor[color];
  const Warna2 = variantGradient[color];

  return (
    <Avatar style={{backgroundColor: gradient ? Warna2 : Warna, height: size, width: size}}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
    </Avatar>
  );
}

AvatarIcon.propTypes = {
  gradient: PropTypes.bool,
  size: PropTypes.number,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning', 'finance', 'people', 'store', 'wallet', 'expense', 'person', 'disable']).isRequired,
  color: PropTypes.oneOf(['error', 'info', 'success', 'warning', 'disable']).isRequired,
};

AvatarIcon.defaultProps = {
  variant: 'success',
  color: 'success',
  gradient: false,
  size: 48
};