export default {
    '@global': {
        '*': {
          'scrollbar-width': 'thin',
        },
        '*::webkit-scrollbar': {
          width: '4px',
          height: '4px',
        },
      },
  };