const UrlServer = {
	address: "https://server.bilas.id/", 
	address2: "https://server2.bilas.id/",
	// socket: "https://dev.bilas.id",
	fcm: "https://fcm.googleapis.com/fcm/send",
	// address: "http://35.240.235.163:3003/",
	// socket: "http://35.240.235.163:3003",
	// address: "http://localhost:3000/", 
	// socket: "http://localhost:3000",
	homepage: "https://official.bilas.id/"
}
 
export default UrlServer;    
