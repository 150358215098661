import FCM from "constants/fcmkey";

const API_URL = "https://fcm.googleapis.com/fcm/send";

class FirebaseClient {

	async send(body, type) {
		if (FCM.key === 'YOUR_API_KEY') {
			console.log('Set your API_KEY in app/FirebaseConstants.js')
			return;
		}
		let headers = new Headers({
			"Content-Type": "application/json",
			"Authorization": "key=" + FCM.key
		});

		try {
			let response = await fetch(API_URL, { method: "POST", headers, body });
			console.log(response);
		} catch (err) {
			console.log(err && err.message)
		}
	}

}

export const sendingFCM = (to) => {
	let firebaseClient = new FirebaseClient();
    const send = {
      "to": to,
      "collapse_key" : "type_a",
      "notification" : {
          "body" : "Body of Your Notification",
          "title": "Title of Your Notification"
      },
      "data" : {
          "body" : "Body of Your Notification in Data",
          "title": "Title of Your Notification in Title",
          "key_1" : "Value for key_1",
          "key_2" : "Value for key_2"
      }
    }
    firebaseClient.send(JSON.stringify(send), "notification");
}

let firebaseClient = new FirebaseClient();
export default firebaseClient;