import React, { Fragment, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Divider, Paper, Button, Typography } from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import { Context } from 'context';
import useRouter from 'utils/useRouter';
import { Navigation, Label, Header } from 'components';
import navigation, { admin, Sales, CS, Writer, SalesMobile, Partner, Partner2, SalesOfficer } from '../../../constants/navigation';
import GlobalStyles from 'constants/style';
import packageJson from '../../../../package.json';

import UrlServer from 'constants/urlserver';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.navbar,
    height: '100%',
    width: '240px',
    minWidth: '240px'
  },
  content: {
    padding: theme.spacing(1)
  },
  brand: {
    padding: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2)
  },
}));

const NavBar = props => {
  const { lastSync, openMobile, onMobileClose, handlingSinkron, handleRestartAPI, className, ...rest } = props;

  const globalClasses = GlobalStyles()
  const classes = useStyles();
  const router = useRouter();
  const [last, setLast] = useState('')
  const [akses, setAkses] = useState([]);
  const [data, setDataLogin] = useState([]);
  const [dt, setData] = useState('');
  const [dtaksesvip, setDataAksesVIP] =  useState('');
  const [state, dispatch] = React.useContext(Context);

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
    const dtlog = JSON.parse(localStorage.getItem('datalog'));
    dtlog && setAkses(dtlog.hak_akses);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  useEffect(() => {
    setLast(lastSync)
    fetchData()
  }, [])

  async function fetchData() {
    const dtlog = JSON.parse(localStorage.getItem('datalog'));
    console.log("data id login api", dtlog)
    
    if(dtlog && dtlog.id_relasi !== null){ // biar api saldo partner tidak di get terus
    const res = await fetch(UrlServer.address+'api/v1/saldo/'+(dtlog.hak_akses == 'Sales'?'sales/':'partner/')+dtlog.id_relasi, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt',
      },
    });
    res.json().then(res => {
      const list = res.result

      setData(list[0])
      // console.log("data saldo",list[0])
    })

    if(dtlog.hak_akses == 'Partner') {
    const ress = await fetch(UrlServer.address + 'api/v1/partner/'+ dtlog.id_relasi, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt',
      },
    });
    ress.json().then(res => {
      const list = res.result
      // console.log('sss', list)
      setDataAksesVIP(list[0].akses_vip)
    })
    }
  }else{
    setData('')
    setDataAksesVIP('')
  }
  }

  const navbarContent = (
    <div className={classes.content}>
        <div className={classes.brand}>
          <RouterLink to="/">
            <div className={globalClasses.flexColumn}>
            <img
              alt="Logo"
              src="/images/brand/logobilas3.png"
            />
            <Label color="orange" className={globalClasses.margin_l_10}>v{packageJson.version}</Label>
            </div>
          </RouterLink>
          
          <Divider className={classes.divider} />
        </div>
        {akses == 'Sales' || akses == 'Partner' ?
        <div  className={classes.brand}>
        {/* <Header title="Saldo Saya" /> */}
          {/* <h3>Saldo Saya</h3> */}
          <h2 align='center' style={{color:'white'}}>Saldo : {dt.saldo ? dt.saldo :'0'},-</h2>
          <Divider className={classes.divider} />  
        </div>:''}
      { akses == "SuperAdmin" ?
      <nav>
      {navigation.map(list => (
        <Navigation
          component="div"
          key={list.title}
          pages={list.pages}
          title={list.title}
        />
      ))}
    </nav>: akses == "Admin" ?
      <nav>
        {admin.map(list => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
          />
        ))}
      </nav>: akses == "CS" ?
      <nav>
      {CS.map(list => (
        <Navigation
          component="div"
          key={list.title}
          pages={list.pages}
          title={list.title}
        />
      ))}
    </nav>: akses == "SalesOfficer" ?
     <nav>
     {SalesOfficer.map(list => (
       <Navigation
         component="div"
         key={list.title}
         pages={list.pages}
         title={list.title}
       />
     ))}
   </nav>
  //  : akses == "Keuangan" ?
  //   <nav>
  //   {Keuangan.map(list => (
  //     <Navigation
  //       component="div"
  //       key={list.title}
  //       pages={list.pages}
  //       title={list.title} 
  //     />
  //   ))}
  // </nav>
  : akses == "Writer" ?
  <nav>
  {Writer.map(list => (
    <Navigation
      component="div"
      key={list.title}
      pages={list.pages}
      title={list.title}
    />
  ))}
</nav>: akses == "Partner" ?
  (dtaksesvip ?
  <nav>
  {Partner.map(list => (
    <Navigation
      component="div"
      key={list.title}
      pages={list.pages}
      title={list.title}
    />
  ))}
</nav>:
<nav>
{Partner2.map(list => (
  <Navigation
    component="div"
    key={list.title}
    pages={list.pages}
    title={list.title}
  />
))}
</nav>)
:
<div>
<Hidden mdDown>
  <nav>
    {Sales.map(list => (
      <Navigation
        component="div"
        key={list.title}
        pages={list.pages}
        title={list.title}
      />
    ))}
  </nav>
  </Hidden>
  <Hidden lgUp>
    <nav>
      {SalesMobile.map(list => (
        <Navigation
          component="div"
          key={list.title}
          pages={list.pages}
          title={list.title}
        />
      ))}
    </nav>
  </Hidden> 
  </div>
}
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div
            {...rest}
            className={clsx(classes.root, globalClasses.flexContainer, globalClasses.flexColumn)}
          >
            <PerfectScrollbar>
              <div className={globalClasses.flexOne}>
                {navbarContent}
              </div>
            </PerfectScrollbar>
            {/* <Divider /> */}
            {akses == "SuperAdmin" ?
            <div className={clsx(globalClasses.flexContainerCenter, globalClasses.flexColumn)}>
            <Typography className={globalClasses.margin_t_10} style={{color:'#FFF', display:'flex'}} variant="body2">LAST SYNC : {last}</Typography>
            <Button style={{width: '200px'}} className={clsx(globalClasses.buttonError, globalClasses.textNoneStyle, globalClasses.margin_all_5)} onClick={handlingSinkron}>
              SINKRONISASI
            </Button>
          </div>  : 
        //   akses == "CS" ? 
        //   <div className={clsx(globalClasses.flexContainerCenter, globalClasses.flexColumn)}>
        //     <Button style={{width: '200px'}} className={clsx(globalClasses.buttonPrimary, globalClasses.textNoneStyle, globalClasses.margin_all_5)} onClick={handleRestartAPI}>
        //     RESTART API
        //   </Button>
        // </div>
        //   : 
          ""}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>

        <Paper
          {...rest}
          className={clsx(classes.root, globalClasses.flexContainer, globalClasses.flexColumn)}
          elevation={0}
          square
        >
          <PerfectScrollbar>
            <div className={globalClasses.flexOne}>
              {navbarContent}
            </div>
          </PerfectScrollbar>
          {/* <Divider /> */}
          {akses == "SuperAdmin" ?
            <div className={clsx(globalClasses.flexContainerCenter, globalClasses.flexColumn)}>
            <Typography className={globalClasses.margin_t_10} style={{color:'#FFF', display:'flex'}} variant="body2">LAST SYNC : {last}</Typography>
            <Button style={{width: '200px'}} className={clsx(globalClasses.buttonError, globalClasses.textNoneStyle, globalClasses.margin_all_5)} onClick={handlingSinkron}>
              SINKRONISASI
            </Button>
          </div> : 
        //   akses == "CS" ? 
        //   <div className={clsx(globalClasses.flexContainerCenter, globalClasses.flexColumn)}>
        //     <Button style={{width: '200px'}} className={clsx(globalClasses.buttonPrimary, globalClasses.textNoneStyle, globalClasses.margin_all_5)} onClick={handleRestartAPI}>
        //     RESTART API
        //   </Button>
        // </div>
        //   : 
          ""}
        </Paper>

      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  handlingSinkron: PropTypes.func,
  handleRestartAPI: PropTypes.func
};

export default NavBar;
