import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import GlobalStyles from 'constants/style';
import { YesNoButton } from 'components';

const Header = props => {
  const { onButtonClick, onCancelClick, formName, className, ...rest } = props;

  const classes = GlobalStyles();
  const buttonShow = props.button ? props.button : false 
  const buttonVar = props.buttonvarian ? props.buttonvarian : classes.buttonPrimary 
  const buttonYesNo = props.yesno ? props.yesno : false
  const textYes = props.buttontext ? props.buttontext : 'Yes'
  const textNo = props.buttoncancel ? props.buttoncancel : 'No'

  return (
    <div
      {...rest}
      className={clsx(classes.margin_b_10, classes.margin_t_10, className)}
    >
      <Grid
        container
        justify="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography variant="h4">
            {props.title}
          </Typography>
        </Grid>
        {buttonShow &&
          <Grid item>
            {buttonYesNo ?
              <YesNoButton formName={formName} yesText={textYes} noText={textNo} onYesButton={onButtonClick} onNoButton={onCancelClick} />
            :
              <button type="submit" form={formName} className={buttonVar} onClick={onButtonClick}>{textYes}</button>
            }
          </Grid>
        }
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  formName: PropTypes.string,
  onButtonClick: PropTypes.func,
  onCancelClick: PropTypes.func
};

export default Header;
