import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Card, Typography, } from '@material-ui/core';
import { Label, AvatarIcon } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    // justifyContent: 'space-between'
  },
  container: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    // alignItems: 'center',
    // flexWrap: 'wrap',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  different: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    margin: theme.spacing(0.5)
  },
  avatar: {
    height: 48,
    width: 48
  }
}));



function CardBlock(props) {
  const { onHandleClick } = props
  var nominal = '';
  const classes = useStyles();
  const def = {
    title: '',
    value: 0,
    prev_value: 0,
    variant: 'info',
    color: 'info'
  };

  let data = props.data != null ? props.data : def
  let statistis = props.statistis != null ? props.statistis : true

  if(data.value != undefined){
 
  let reverse = JSON.stringify(data.value).split('').reverse().join(''),
                ribuan = reverse.match(/\d{1,3}/g);
                ribuan = ribuan == null ? ribuan : ribuan.join('.').split('').reverse().join('');
                nominal = ribuan;
  }

  return (
    <Card className={classes.root} onClick={onHandleClick}>
      <div className={classes.container}>
        <div>
          <Typography variant="h5" gutterBottom>
            {data.title}
          </Typography>
          <div className={classes.details}>
            <Typography variant="h2">
              {nominal}
            </Typography>
             <AvatarIcon color={data.color} variant={data.variant} />
          </div>
        </div>
       
      </div>
      {statistis &&
      <div className={classes.different}>
        <Label className={classes.label} color={data.value >= data.prev_value ? 'green' : 'red'}>
          {data.value >= data.prev_value ? '+' : '-'}{Math.round((Math.abs(data.value - data.prev_value) / data.prev_value) * 100,2)}%
        </Label>
        <Typography variant="h6">
          dari bulan lalu
        </Typography>
      </div>
    }
    </Card>
  );
}

export default CardBlock;

CardBlock.propTypes = {
  onHandleClick: PropTypes.func,
};
