import { colors } from '@material-ui/core';

const white = '#FFF';
const black = '#2D2D2D';
const basic = '#1E1E1E';
const strip = '#2D2D2D';
const navbar = '#1E1E1E';

export default {
  black,
  white,
  basic,
  strip,
  navbar,
  primary: {
    contrastText: white,
    dark: '#1976d2',
    main: '#1976d2',
    light: strip
  },
  secondary: {
    contrastText: white,
    dark: '#f57c00',
    main: '#f57c00',
    light: '#FFE082'
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: white,
    secondary: 'rgba(255, 255, 255, 0.7)',
    link: 'rgba(255, 255, 255, 0.7)'
  },
  link: 'rgba(255, 255, 255, 0.7)',
  icon: white,
  background: {
    default: '#1E1E1E',
    paper: black
  },
  divider: 'rgba(255, 255, 255, 0.12)'
};
