import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </MuiDialogTitle>
    );
});

const Modal = props => {
    const { onPositiveButtonClick, 
        onNegativeButtonClick, 
        onOpenModal, 
        onCloseModal, 
        varian, 
        text, 
        content, 
        title, 
        full, 
        action 
    } = props;

    let positiveButton = props.positive_button ? props.positive_button : "Ya"
    let negativeButton = props.negative_button ? props.negative_button : "Batal"

    return (
        <Dialog
            fullWidth={full}
            maxWidth={full == true ? false : 'md'}
            open={onOpenModal}
            onClose={onCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" onClose={onCloseModal}>{title}</DialogTitle>
            <DialogContent dividers>
                {varian == 'alert' &&
                    <DialogContentText id="alert-dialog-description">
                        {text}
                    </DialogContentText>
                }
                {varian == 'custom' &&
                    <div>
                        {content}
                    </div>
                }
            </DialogContent>
            {action != 'custom' &&
                <DialogActions>
                    {action != 'yesonly' &&
                        <Button onClick={onNegativeButtonClick} color="primary">
                            {negativeButton}
                        </Button>
                    }
                    <Button onClick={onPositiveButtonClick} color="primary" autoFocus>
                        {positiveButton}
                    </Button>
                </DialogActions>
            }
        </Dialog>
    );
}

Modal.propTypes = {
    title: PropTypes.string.isRequired,
    varian: PropTypes.oneOf(['alert', 'custom']),
    onPositiveButtonClick: PropTypes.func,
    onNegativeButtonClick: PropTypes.func,
    onOpenModal: PropTypes.bool,
    onCloseModal: PropTypes.func,
    text: PropTypes.string.isRequired,
    content: PropTypes.element,
    full: PropTypes.bool.isRequired,
    action: PropTypes.oneOf(['yesno', 'yesonly', 'custom'])
};

Modal.defaultProps = {
    varian: 'alert',
    shape: 'square',
    full: false,
    action: 'yesno'
};

export default Modal;