import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import MaterialTable from "material-table";
import { makeStyles, withStyles } from '@material-ui/styles';
import { Paper, Button, colors } from '@material-ui/core';
import theme from 'theme';
import moment from 'moment';
import { Context } from 'context';

const ColorButton = withStyles(() => ({
    root: {
        fontSize: '11px',
        fontWeight: 600,
        letterSpacing: '0.33px',
        lineHeight: '13px',
        textTransform: 'uppercase',
        marginRight: '5px',
        minWidth: '60px',
        minHeight: '30px',
        border: '1px solid none',
    },
}))(Button);

const useStyles = makeStyles(theme => ({
    button: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        }
    },
    buttonView: {
        color: '#FFFFFF',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900],
        }
    },
    buttonEdit: {
        color: '#FFFFFF',
        backgroundColor: colors.amber[600],
        '&:hover': {
            backgroundColor: colors.amber[900],
        }
    },
    buttonError: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
        }
    },
    outline: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        backgroundColor: 'none',
    },
    outlineView: {
        color: colors.green[600],
        borderColor: colors.green[600],
        backgroundColor: 'none',
    },
    outlineEdit: {
        color: colors.amber[600],
        borderColor: colors.amber[600],
        backgroundColor: 'none',
    },
    outlineError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        backgroundColor: 'none',
    },
}));

export default function Table(props) {
const [state, dispatch] = useContext(Context);
    const classes = useStyles()
    const _filefy = require("filefy");
    const { headerNone, headerColor, isDense, isStripped,
        useSearch, useFilter, useSorting, useToolbar, useSelection, useExport, exportLayanan, useColumn, usePaging,
        positionAction, listAction, typeAction, variantAction, customAction, customActionList,
        onEditClick, onDeleteClick, onViewClick,
        pageSize, pageCurrent, pageSizeOption, fixedLeftColumn, 
        ...other } = props;

    const styleActionButton = {
        Container: props => <Paper {...props} />,
        Action: props => {
            if (props.action.icon === 'edit') {
                return (
                    <ColorButton className={variantAction === 'outlined' ? classes.outlineEdit : classes.buttonEdit} onClick={(event) => props.action.onClick(event, props.data)}>{props.action.icon}</ColorButton>
                )
            } else if (props.action.icon === 'delete') {
                return (
                    <ColorButton className={variantAction === 'outlined' ? classes.outlineError : classes.buttonError} onClick={(event) => props.action.onClick(event, props.data)}>{props.action.icon}</ColorButton>
                )
            } else if (props.action.icon === 'visibility') {
                return (
                    <ColorButton className={variantAction === 'outlined' ? classes.outlineView : classes.buttonView} onClick={(event) => props.action.onClick(event, props.data)}>View</ColorButton>
                )
            } else {
                return (
                    <ColorButton className={variantAction === 'outlined' ? classes.outline : props.action.color} onClick={(event) => props.action.onClick(event, props.data)}>{props.action.icon}</ColorButton>
                )
            }
        }
    }

    const styleActionIcon = {
        Container: props => <Paper {...props} />
    }

    const styleNoneBorder = {
        Container: props => <div {...props} />
    }

    const setAction = () => {
        const myAction = []
        const view = { icon: 'visibility', onClick: (event, rowData) => onViewClick(event, rowData) }
        const edit = { icon: 'edit', onClick: (event, rowData) => onEditClick(event, rowData) }
        const del = { icon: 'delete', onClick: (event, rowData) => onDeleteClick(event, rowData) }

        listAction.map(aksi => {
            if (aksi === 'view') {
                myAction.push(view)
            } else if (aksi === 'edit') {
                myAction.push(edit)
            } else if (aksi === 'delete') {
                myAction.push(del)
            }
        })

        return myAction
    }

    const handleExportCsv = (allColumns, allData) => {
        const columns = allColumns.filter(columnDef => columnDef["hidden"] !== true);
        // columns.push({"field" : "data_layanan", "title" : "Daftar Layanan"})
        // var columns2 = [{"field" : "nama", "title" : "Nama"},{"field" : "biaya", "title" : "Harga"}]
        const exp = [];
        allData.forEach(el => {
            // el['data_layanan'] = columns2.map(columnDef => columnDef.title);
            
            exp.push(el);
            exp.push({"nama" : "DETAIL LAYANAN", "cci" : "SATUAN", "kring" : "HARGA", "strika" : "WAKTU"})
            let x =  el.layanan;
            x.forEach(el => {
                // masukkan ke kolom yang sama dengan parent
                el['cci'] = el.satuan;
                el['kring'] = el.biaya;
                el['strika'] = el.waktu+" Jam"
                // exp.push({data_layanan : columns2.map(columnDef => el[columnDef.field])})
                // exp.push({"nama" : "layanan", "cci" : "biaya"})
                exp.push(el)
             })
            // el['data_layanan'] = x.map((d) => Object.values(d))
        })

        console.log("baris",exp)
        // console.log("kolom",columns)
        const exportedData = exp.map(rowData => columns.map(columnDef =>
            rowData[columnDef.field]
        ));
        new _filefy.CsvBuilder('Export Layanan ' + moment().format('YYYY-MM-DD'))
          .setDelimeter(';')
          .setColumns(columns.map(columnDef => columnDef.title))
          .addRows(exportedData)
          .exportFile();
      }

    return (
        <MaterialTable
            {...other}
            components={headerNone ? styleNoneBorder : typeAction === 'icon' ? styleActionIcon : styleActionButton}
            actions={customAction ? customActionList : setAction()}
            options={{
                actionsColumnIndex: positionAction === 'left' ? 0 : -1,
                padding: isDense ? "dense" : "default",
                pageSizeOptions: pageSizeOption,
                pageSize: pageSize,
                initialPage: pageCurrent,
                filtering: useFilter,
                search: useSearch,
                sorting: useSorting,
                toolbar: useToolbar,
                selection: useSelection,
                exportButton: useExport,
                exportCsv: exportLayanan == true ? handleExportCsv : '',
                exportAllData: true,
                columnsButton: useColumn,
                paging: usePaging,
                headerStyle: { backgroundColor: state.theme == false  ? '#FAFAFA' : '#4D4F4F' },
                fixedColumns: {
                    left: fixedLeftColumn,
                    right: 0
                  },
                rowStyle: rowData => {
                    if (isStripped) {
                        if (rowData.tableData.id % 2) {
                            return { backgroundColor: state.theme == false ? '#FAFAFA' : '#303030'};
                        } else {
                            return { backgroundColor: state.theme == false ? 'white' : '#424242'};
                        }
                    }
                },
            }}
        />
    );
}

Table.propTypes = {
    headerNone: PropTypes.bool,
    headerColor: PropTypes.string,
    isDense: PropTypes.bool,
    isStripped: PropTypes.bool,
    useSearch: PropTypes.bool,
    useFilter: PropTypes.bool,
    useSorting: PropTypes.bool,
    useToolbar: PropTypes.bool,
    useSelection: PropTypes.bool,
    positionAction: PropTypes.oneOf(['left', 'right']),
    listAction: PropTypes.array,
    typeAction: PropTypes.oneOf(['icon', 'button']),
    variantAction: PropTypes.oneOf(['outlined', 'contained']),
    customAction: PropTypes.bool,
    customActionList: PropTypes.array,
    onViewClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    pageSize: PropTypes.number,
    pageSizeOption: PropTypes.array,
    pageCurrent: PropTypes.number,
    usePaging: PropTypes.bool,
    useColumn: PropTypes.bool,
    useExport: PropTypes.bool,
    exportLayanan:  PropTypes.bool,
    fixedLeftColumn : PropTypes.number
};

Table.defaultProps = {
    headerNone: false,
    headerColor: '#FAFAFA',
    isDense: false,
    isStripped: false,
    useSearch: true,
    useFilter: true,
    useSorting: true,
    useToolbar: true,
    useSelection: false,
    positionAction: 'right',
    listAction: ['view', 'edit', 'delete'],
    typeAction: 'button',
    variantAction: 'contained',
    customAction: false,
    pageSize: 5,
    pageSizeOption: [5, 10, 25, 50],
    pageCurrent: 0,
    usePaging: true,
    useExport: true,
    exportLayanan : false,
    useColumn: true,
    fixedLeftColumn: 0,
};
