import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  colors
} from '@material-ui/core';
import { Label } from 'components';

const useStyles = makeStyles(theme => ({
  active: {
    boxShadow: `inset 4px 0px 0px ${theme.palette.primary.main}`,
    backgroundColor: colors.grey[50]
  },
  avatar: {
    height: 40,
    width: 40
  },
  details: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  unread: {
    marginTop: 2,
    padding: 2,
    height: 18,
    minWidth: 18
  }
}));

const ChatListItem = props => {

  const { className, active, conversation, onItemClick, ...rest } = props;
  const classes = useStyles();
  const lastMessage = conversation.messages[conversation.messages.length - 1];

  return (
    <ListItem {...rest}
      button
      className={clsx(
        {
          [classes.active]: active
        },
        className
      )}
      component={RouterLink}
      to={`/livechat/${conversation.nama}`}
      onClick={onItemClick}
    >
      <ListItemAvatar>
        <Avatar
          alt="Person"
          className={classes.avatar}
        />
      </ListItemAvatar>
      <ListItemText
        primary={conversation.temp ? conversation.email : conversation.nama}
        primaryTypographyProps={{
          noWrap: true,
          variant: 'h6'
        }}
        secondary={lastMessage ? `${lastMessage.author}: ${lastMessage.message}` : ''}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'body1'
        }}
      />
      <div className={classes.details}>
        <Typography noWrap variant="body2">
          {moment(conversation.last_message).isSame(moment(), 'day')
            ? moment(conversation.last_message).format('LT')
            : moment(conversation.last_message).fromNow()}
        </Typography>
        <Label className={classes.unread} color={conversation.active ? colors.green[500] : colors.red[500]} shape="rounded" />
      </div>
    </ListItem>
  );
};

ChatListItem.propTypes = {
  conversation: PropTypes.object,
  onItemClick: PropTypes.func,
  active: PropTypes.bool,
  className: PropTypes.string,
};

export default ChatListItem;
