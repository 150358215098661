import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import { Chart } from './Chart'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {},
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  inner: {
    height: 367,
    minWidth: 500
  },
  chart: {
    height: '100%'
  }
}));


function ChartLine(props) {

  const classes = useStyles();
  const def = {
    title: '',
    values: [],
    labels: [],
  };

  let data = props.data != null ? props.data : def

  return (
    <Card
      className={classes.root}
    >
      <CardHeader
        title={data.title}
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Chart
              className={classes.chart}
              data={data.values}
              labels={data.labels}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
}

export default ChartLine;
